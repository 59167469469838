
const DisplayTrainer = ({trainerList, teacher, setTeacher}) => {
    if (trainerList) {
        return (
            <div className="">
                <div className="px-2 pb-1">선생님</div>
                <select className='w-full' value={teacher} onChange={(e) => { setTeacher(e.target.value) }}>
                    <option value="">담당 선생님</option>
                    {trainerList.map((value, index) => {
                        return (<option key={"trainer_" + index} value={value}>{value}</option>)
                    })}
                </select>
            </div>
        )
    }
}

export default DisplayTrainer;