

// const result = await customAxios.get("/api/getSignUpExamTable", {
//     params: {
//         UID: examUID,
//     }
// })


export const categoryList = [
    {label:"오디션 보컬", value :"vocal"},
    // {label:"오디션 댄스", value :"dance"},
    {label:"오디션 보컬/댄스", value :"audition"},
    {label:"입시", value :"entrance"},
    {label:"전문", value :"pro"},
    // {label:"뮤지컬", value :"musical"},
]

// categoryList.findIndex(category => category.value === value);

export const examStateList = [
    {label:"진행", value :"inProgress", color:"deep-orange"},
    {label:"마감", value :"terminated", color:"gray"},
]


export let deliveryStateStruct = [
    {
        label: "결제 완료(상품 준비중)",
        value: "paid",
        className : "bg-green-300",
    },
    {
        label: "환불",
        value: "canceled",
        className : "bg-red-400",
    },
    {
        label: "배송 준비중",
        value: "preparing",
        className : "bg-blue-200",
    },
    {
        label: "배송 시작",
        value: "start",
        className : "bg-blue-300",
    },
    {
        label: "배송중",
        value: "inDelivery",
        className : "bg-blue-400",
    },
    {
        label: "배송 완료",
        value: "deliverd",
        className : "bg-blue-500",
    },
]

export let Inquiry_CategoryList = [
    {
        label: "상품 문의",
        value: "product",
    },
    {
        label: "기타 문의",
        value: "etc",
    },
]

export const removeEmoji = (text) => {
    const emojis = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return text.replace(emojis, '');
 }