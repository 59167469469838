const DisplayBody = ({value1, onChange1, value2, onChange2}) => {
    return (
        <div className="flex w-full gap-2">
            <div className="flex-col w-full">
                <div className="px-2 pb-1">키</div>
                <div className="flex gap-2 items-center">
                    <input className="w-full" type="number" value={value1} onChange={(e) => onChange1(e.target.value)} maxLength={255} min={0} max={999} step={0.1}/>
                    <div className="px-2">cm</div>
                </div>
            </div>
            <div className="flex-col w-full">
                <div className="px-2 pb-1">몸무게</div>
                <div className="flex gap-2 items-center">
                    <input className="w-full" type="number" value={value2} onChange={(e) => onChange2(e.target.value)} maxLength={255}  min={0} max={999} step={0.1}/>
                    <div className="px-2">kg</div>
                </div>
            </div>
        </div>
    )
}

export default DisplayBody;