import React, { useState } from 'react';

const RadioOX = ({ radio, label, name, onRadioChange }) => {
  return (
    <div className="flex-col">
        <label className='text-sm '>{label}</label>
        <div className='flex gap-10 px-1 pt-1 w-full'>
            <div className='flex gap-1'>
                <input className='' id={'radio-o'+name} type="radio" value="O" checked={radio === "O"} onChange={() => onRadioChange("O")} />
                <label className='text-sm ' htmlFor={'radio-o'+name}>O</label>
            </div>
            <div className='flex gap-1'>    
                <input id={'radio-x'+name} type="radio" value="X" checked={radio === "X"} onChange={() => onRadioChange("X")} />
                <label className='text-sm ' htmlFor={'radio-x'+name}>X</label>
            </div>
        </div>
    </div>
  );
};

export default RadioOX;
