import { useEffect, useState } from "react";
import { Card, Input, Textarea, } from "@material-tailwind/react";
import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";

// import CategoryList from "../Checkbox/CategoryList"
// import { categoryList } from "../Common/dataStruct";
import dayjs from "dayjs";


function WriteAdminAuditionForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const auditionUID = searchParams.get('Audition_UID')=== null ? '' : searchParams.get('Audition_UID')
    const [auditionTitle, setAuditionTitle] = useState('');
    const [auditionText, setAuditionText] = useState(dayjs().get('month')+1)
    const [auditionContext, setAuditionContext] = useState('')
    const [auditionLimit, setAuditionLimit] = useState(20)

    useEffect(() => {
        setStart(true)
    }, [])


    useEffect(() => {
        const getAuditionData = async () => {
            const res = await customAxios.get('/api/getAudition' + "?page=" + auditionUID)
            // console.log(res.data)
            if(res.data && res.data.state===101){

                const auditionData = res.data.response

                if(auditionData.Audition_Title)
                    setAuditionTitle(auditionData.Audition_Title)
                if(auditionData.Audition_Text)
                    setAuditionText(auditionData.Audition_Text)
                // if(auditionData.Audition_Category){
                        // const objExamCategory = JSON.parse(auditionData.Exam_Category)

                        // const initialBoxState = []
                        // categoryList.forEach(() => {
                        //     initialBoxState.push(false)
                        // })

                        // objExamCategory.forEach((value)=>{
                        //     const index = categoryList.findIndex(category => category.value === value);
                        //     initialBoxState[index] = true
                        // })

                        // setCheckBoxStates(initialBoxState)

                    // console.log(examData.Exam_Category)
                // }
                if(auditionData.Audition_Context)
                    setAuditionContext(auditionData.Audition_Context)
                if(auditionData.Audition_Limit)
                    setAuditionLimit(auditionData.Audition_Limit)

            }
        }
        if (start) {
            if(auditionUID !== '')
                getAuditionData()
        }
    }, [start, auditionUID])

    // const initialBoxState = []
    // categoryList.forEach(() => {
    //     initialBoxState.push(false)
    // })
    // const [checkBoxStates, setCheckBoxStates] = useState(initialBoxState)

    // const onChangedCheckBox = (index) => {
    //     const nextCheckBoxStates = checkBoxStates.map((element, i) => {
    //         if (index === i) {
    //             return element = !element
    //         }
    //         else {
    //             return element
    //         }
    //     })
    //     setCheckBoxStates(nextCheckBoxStates)
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        

        // let check = false
        // checkBoxStates.forEach((element)=>{
        //     if(element)
        //         check = true 
        // })
        // if(!check){
        //     alert("분류를 선택해주세요.")
        //     return
        // }

        if (window.confirm('등록 하시겠습니까?')) {
            try {
                // const examCategory = checkBoxStates.map((element, index) => element ? categoryList[index].value : null).
                // filter(value => value !== null);

                const response = await customAxios.post('/api/writeAudition', {
                        Audition_UID: auditionUID,
                        Audition_Title: auditionTitle,
                        Audition_Context: auditionContext,
                        Audition_Limit: auditionLimit,
                        Audition_Text: String(auditionText),
                    }
                )
                if (response.data) {
                    if (response.data.state === 100) {
                        alert("등록이 완료되었습니다.")
                        navigate("/AdminAudition")
                    }
                    else {
                        let message = response.data.message
                        if(message === 'noTitle'){
                            message = '제목을 입력해주세요.'
                        }
                        else if(message === 'noText'){
                            message = '오디션 월을 입력해주세요.'
                        }
                        else if(message === 'noLimit'){
                            message = '제한 인원을 입력해주세요.'
                        }
                        else if(message === 'noContext'){
                            message = '내용을 입력해주세요.'
                        }
                            alert(message)
                    }
                }
                else {
                    alert("요청에 실패하였습니다.")
                }
                // console.log(' created successfully:', response.data);
            } catch (error) {
                // console.error('Error creating:', error);
            }
        }
    };

    return (
        <section className="flex p-2 w-full justify-start">
            <article className='main-visual_wrap'>
                <Card className="pt-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col w-80 gap-2">
                            <div className="">
                                <Textarea 
                                    className="text-lg" 
                                    label="오디션 제목" 
                                    value={auditionTitle}
                                    rows={2}
                                    maxLength={255}
                                    onChange={(e) => setAuditionTitle(e.target.value)} />
                            </div>
                            <div className="">
                                <Input 
                                    className="" 
                                    label="( ) 월 오디션"
                                    type="number"
                                    min={1}
                                    max={12}
                                    step={1}
                                    value={auditionText}
                                    onChange={(e)=>{
                                        let text = e.target.value
                                        let num = Number(text)
                                        
                                        if(num>12)
                                            num=12
                                        if(num<1)
                                            num=1
                                        
                                        setAuditionText(num)}}
                                />
                            </div>
                            {/* <div>
                                <CategoryList
                                    checkBoxStates={checkBoxStates}
                                    categoryList={categoryList}
                                    onChangedCheckBox={onChangedCheckBox}
                                />
                            </div> */}
                            <Textarea
                                className="min-h-full "
                                label="내용"
                                value={auditionContext}
                                rows={35}
                                maxLength={1000}
                                onChange={(e) => setAuditionContext(e.target.value)} />
                            <div>
                                <Input type="number" label="제한 인원" value={auditionLimit} onChange={(e) => setAuditionLimit(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button type="submit" className="rounded bg-Cgcolor text-white" > 등록 </button>
                        </div>
                    </form>
                </Card>
            </article>
        </section>
    )
}

export default WriteAdminAuditionForm;
