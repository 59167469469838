

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Lee from '../../Images/Main/main05.png';

import { TypeAnimation } from 'react-type-animation';
import React from "react";

import Header from './Header';
import Footer from './Footer';

//SLICK
function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, right: -20, }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, left: -20, zIndex: 1, }}
            onClick={onClick}
        />
    );
}
//SLICK END

function HomeData() {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
        <div>
            <section>
                <article className='main-visual_wrap bg-black'>
                    <div className='mx-auto max-w-7xl p-5'>
                        <div className=''>
                            <div className=''>
                                <div className='text-white '>
                                    <h1>창꼬는 "창고"를 발음대로 쓴 말로, 물건이나 자재를</h1>
                                    <h2>저장해두는 창고처럼 모든 문화적,예술적 요소들을 담아두고 있다는 뜻입니다</h2>
                                    <h3>창꼬의 전문가들과 함께, 실현 가능한 목표로 만드세요.</h3>
                                    <div className='text-lg font-bold mt-3 mb-5'>
                                        <TypeAnimation
                                            sequence={[
                                                '창꼬의 전문가와 함께,실현 가능한 목표로 만드세요.', // Types 'One'
                                                1000, // Waits 1s
                                                ' 당신의 목표를 이루기 위해 창꼬가 함께합니다.', // Deletes 'One' and types 'Two'
                                                2000, // Waits 2s
                                                () => {
                                                    // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                                }
                                            ]}
                                            wrapper="div"
                                            cursor={true}
                                            repeat={Infinity}
                                        />
                                    </div>
                                    <ul className='flex space-x-3 text-black'>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#보컬</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#댄스</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#뮤지컬</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#오디션</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#취미</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='mt-5 mb'>
                                <img src={Lee} className=' w-full' alt='메인 이미지' />
                                {/* <a href="http://192.168.0.101:3000/SignUpExam?page=c46ecb64-a3b7-40e8-b6b4-936c877179e4">
                                    <img src={Lee} className=' w-full' alt='메인 이미지' />
                                </a> */}
                            </div>
                        </div>
                    </div>

                </article>
            </section>
        </div>

    );
}

export default HomeData;


