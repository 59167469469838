
import { Route } from "react-router-dom"
import Home from "../../Pages/Home"
import Class from "../../Pages/General/Class"
import EventBoard from "../../Pages/General/EventBoard"
import EventDetail from "../../Pages/General/EventDetail"
import EventWrite from "../../Pages/General/EventWrite"
import Store from "../../Pages/General/Store"
import StoreDetail from "../../Pages/General/StoreDetail"
import PaymentDetail from "../../Pages/General/PaymentDetail"
import PaymentSuccess from "../../Pages/General/PaymentSuccess"
import PaymentDetailSpecial from "../../Pages/General/PaymentDetailSpecial"
import Practice from "../../Pages/General/Practice"
import SignUpExam from "../../Pages/General/SignUpExam"
import SignUpAudition from "../../Pages/General/SignUpAudition"

import Join from "../../Pages/ManagementMember/Join"
import Lost from "../../Pages/ManagementMember/Lost"
import ChangePassword from "../../Pages/ManagementMember/ChangePassword"
import ChangeEmail from "../../Pages/ManagementMember/ChangeEmail"

import LoginKakao from "../../Pages/ManagementMember/LoginKakao"
import LoginNaver from "../../Pages/ManagementMember/LoginNaver"
import LoginGoogle from "../../Pages/ManagementMember/LoginGoogle"
import Login from "../../Pages/ManagementMember/Login"
import LogOut from "../../Pages/ManagementMember/LogOut"
import MyPage from "../../Pages/MyChangGgo/MyPage"
import MyPrivacy from "../../Pages/MyChangGgo/MyPrivacy"
import MyPortfolio from "../../Pages/MyChangGgo/MyPortfolio"
import MyPayment from "../../Pages/MyChangGgo/MyPayment"
import MyInquiry from "../../Pages/MyChangGgo/MyInquiry"
import MyInquiryWrite from "../../Pages/MyChangGgo/MyInquiryWrite"

import AdminHome from "../../Pages/Admin/AdminHome"
import AdminMember from "../../Pages/Admin/AdminMember"
import AdminPayment from "../../Pages/Admin/AdminPayment"
import AdminCategoryPage from "../../Pages/Admin/AdminCategoryPage"
import AdminStorePage from "../../Pages/Admin/AdminStorePage"
import AdminEventBoard from "../../Pages/Admin/AdminEventBoard"

import Privacy from "../../Pages/Etc/Privacy"
import Service from "../../Pages/Etc/Service"
import Test from "../../Pages/Etc/Test"

import EmailCertification from "../../Pages/ManagementMember/EmailCertification"
import DeleteKakao from "../../Pages/MyChangGgo/DeleteKakao"
import DeleteNaver from "../../Pages/MyChangGgo/DeleteNaver"
import DeleteGoogle from "../../Pages/MyChangGgo/DeleteGoogle"
import LogoutKakao from "../../Pages/ManagementMember/LogoutKakao"

import AdminStoreWritePage from "../../Pages/Admin/AdminStoreWritePage"
import AdminProducts from "../../Pages/Admin/AdminProducts"
import AdminPaymentInsert from "../../Pages/Admin/AdminPaymentInsert"
import AdminPaymentLost from "../../Pages/Admin/AdminPaymentLost"
import AdminPractice from "../../Pages/Admin/AdminPractice"
import AdminPractice2 from "../../Pages/Admin/AdminPractice2"
import AdminEventWrite from "../../Pages/Admin/AdminEventWrite"
import AdminExam from "../../Pages/Admin/AdminExam"
import WriteAdminExam from "../../Pages/Admin/WriteAdminExam"
import AdminAudition from "../../Pages/Admin/AdminAudition"
import WriteAdminAudition from "../../Pages/Admin/WriteAdminAudition"
import ExamDetail from "../../Pages/General/ExamDetail"
import SignUpExamDetail from "../../Pages/General/SignUpExamDetail"
import CheckSignUp from "../../Pages/General/CheckSignUp"
import CheckSignUpAudition from "../../Pages/General/CheckSignUpAudition"
import AuditionDetail from "../../Pages/General/AuditionDetail"
import PrivacyPage from "../../Pages/Etc/PrivacyPage"
import ServicePage from "../../Pages/Etc/ServicePage"
//---------------------------------- 일반 페이지 (General)
let GeneralIdx =0
let GeneralPages = [
    {
        name : "홈",
        url : "/",
        idx : GeneralIdx++,
        page : <Home/>
    },
    {
        name : "실용음악학원",
        url : "/class",
        idx : GeneralIdx++,
        page : <Class/>
    },
    {
        name : "스토어",
        url : "/Store",
        idx : GeneralIdx++,
        page : <Store/>
    },
    {
        name : "게시판",
        url : "/EventBoard",
        idx : GeneralIdx++,
        page : <EventBoard/>
    },
    {
        name : "연습실",
        url : "/Practice",
        idx : GeneralIdx++,
        page : <Practice/>
    },
    {
        name : "평가 신청",
        url : "/SignUpExam",
        idx : GeneralIdx++,
        page : <SignUpExam/>
    },
    {
        name : "오디션 신청",
        url : "/SignUpAudition",
        idx : GeneralIdx++,
        page : <SignUpAudition/>
    },
]
GeneralPages.forEach(element => {
    element.route = <Route key={"GeneralPages"+element.idx} path={element.url} element={element.page}></Route>
});
//---------------------------------- 일반 페이지 (General)
//---------------------------------- 일반 페이지 Detail(General)
let GeneralDetailIdx =0
let GeneralDetailPages = [
    {
        name : "창꼬 이벤트 상세 페이지",
        url : "/EventDetail",
        idx : GeneralDetailIdx++,
        page : <EventDetail/>
    },
    {
        name : "이벤트 페이지 작성",
        url : "/EventWrite",
        idx : GeneralDetailIdx++,
        page : <EventWrite/>
    },
    {
        name : "스토어 상세 페이지",
        url : "/StoreDetail",
        idx : GeneralDetailIdx++,
        page : <StoreDetail/>
    },
    {
        name : "결제 상세 페이지",
        url : "/PaymentDetail",
        idx : GeneralDetailIdx++,
        page : <PaymentDetail/>
    },
    {
        name : "결제 완료 페이지",
        url : "/PaymentSuccess",
        idx : GeneralDetailIdx++,
        page : <PaymentSuccess/>
    },
    {
        name : "결제 상세 스페셜 페이지",
        url : "/PaymentDetailSpecial",
        idx : GeneralDetailIdx++,
        page : <PaymentDetailSpecial/>
    },
    {
        name : "평가 상세 페이지",
        url : "/ExamDetail",
        idx : GeneralDetailIdx++,
        page : <ExamDetail/>
    },
    {
        name : "평가 신청 상세 페이지",
        url : "/SignUpExamDetail",
        idx : GeneralDetailIdx++,
        page : <SignUpExamDetail/>
    },
    {
        name : "평가 신청 확인 페이지",
        url : "/SignUpCheck",
        idx : GeneralDetailIdx++,
        page : <CheckSignUp/>
    },
    {
        name : "오디션 상세 페이지",
        url : "/AuditionDetail",
        idx : GeneralDetailIdx++,
        page : <AuditionDetail/>
    },
    {
        name : "오디션 신청 확인 페이지",
        url : "/CheckSignUpAudition",
        idx : GeneralDetailIdx++,
        page : <CheckSignUpAudition/>
    },

    

    
    
]
GeneralDetailPages.forEach(element => {
    element.route = <Route key={"GeneralPages"+element.idx} path={element.url} element={element.page}></Route>
});

//---------------------------------- 일반 페이지 Detail(General)



//---------------------------------- 회원관리 (ManagementMember)
let ManagementMemberIdx = 0
let ManagementMemberPages = [
    {
        name : "회원가입",
        url : "/Join",
        idx : ManagementMemberIdx++,
        page : <Join/>
    },
    {
        name : "비밀번호 찾기",
        url : "/Lost",
        idx : ManagementMemberIdx++,
        page : <Lost/>
    },
    {
        name : "비밀번호 변경",
        url : "/ChangePassword",
        idx : ManagementMemberIdx++,
        page : <ChangePassword/>
    },
    {
        name : "이메일 변경",
        url : "/ChangeEmail",
        idx : ManagementMemberIdx++,
        page : <ChangeEmail/>
    },
    {
        name : "카카오 로그인",
        url : "/kakaoLogin",
        idx : ManagementMemberIdx++,
        page : <LoginKakao/>
    },
    {
        name : "네이버 변경",
        url : "/NaverLogin",
        idx : ManagementMemberIdx++,
        page : <LoginNaver/>
    },
    {
        name : "구글 변경",
        url : "/GoogleLogin",
        idx : ManagementMemberIdx++,
        page : <LoginGoogle/>
    },
    {
        name : "로그인",
        url : "/Login",
        idx : ManagementMemberIdx++,
        page : <Login/>
    },
    {
        name : "로그아웃",
        url : "/Logout",
        idx : ManagementMemberIdx++,
        page : <LogOut/>
    },
    {
        name : "마이 페이지",
        url : "/MyPage",
        idx : ManagementMemberIdx++,
        page : <MyPage/>
    },
    {
        name : "개인 정보 수정",
        url : "/MyPrivacy",
        idx : ManagementMemberIdx++,
        page : <MyPrivacy/>
    },
    {
        name : "나의 구매내역",
        url : "/MyPayment",
        idx : ManagementMemberIdx++,
        page : <MyPayment/>
    },
    {
        name : "나의 문의내역",
        url : "/MyInquiry/*",
        idx : ManagementMemberIdx++,
        page : <MyInquiry/>,
        routeUrl : "/MyInquiry",
    },
    {
        name : "문의하기",
        url : "/MyInquiryWrite",
        idx : ManagementMemberIdx++,
        page : <MyInquiryWrite/>
    },
    {
        name : "이메일 인증",
        url : "/EmailCertification",
        idx : ManagementMemberIdx++,
        page : <EmailCertification/>
    },
    {
        name : "카카오 탈퇴",
        url : "/DeleteKakao",
        idx : ManagementMemberIdx++,
        page : <DeleteKakao/>
    },
    {
        name : "네이버 탈퇴",
        url : "/DeleteNaver",
        idx : ManagementMemberIdx++,
        page : <DeleteNaver/>
    },
    {
        name : "구글 탈퇴",
        url : "/DeleteGoogle",
        idx : ManagementMemberIdx++,
        page : <DeleteGoogle/>
    },
    {
        name : "카카오 로그아웃",
        url : "/LogoutKakao",
        idx : ManagementMemberIdx++,
        page : <LogoutKakao/>
    },

    
    
]

ManagementMemberPages.forEach(element => {
    element.route = <Route key={"ManagementMemberPages"+element.idx} path={element.url} element={element.page}></Route>
});
//---------------------------------- 회원관리 (ManagementMember)

//----------------------------------- admin
let adminIdx = 0
let adminPages = [
    {
        name : "admin Home",
        url : "/admin",
        idx : adminIdx++,
        page : <AdminHome/>
    },
    {
        name : "회원 관리",
        url : "/adminMember",
        idx : adminIdx++,
        page : <AdminMember/>
    },
    {
        name : "분류 관리",
        url : "/adminStore/AdminCategoryPage",
        idx : adminIdx++,
        page : <AdminCategoryPage/>
    },
    {
        name : "스토어 관리",
        url : "/adminStore/AdminStorePage",
        idx : adminIdx++,
        page : <AdminStorePage/>
    },
    {
        name : "상품 관리",
        url : "/adminStore/AdminProducts",
        idx : adminIdx++,
        page : <AdminProducts/>
    },
    {
        name : "주문 관리",
        url : "/adminStore/payment",
        idx : adminIdx++,
        page : <AdminPayment/>
    },
    {
        name : "AdminStoreWritePage",
        url : "/adminStore/AdminStoreWritePage",
        idx : adminIdx++,
        page : <AdminStoreWritePage/>
    },
    {
        name : "AdminPaymentInsert",
        url : "/AdminPaymentInsert",
        idx : adminIdx++,
        page : <AdminPaymentInsert/>
    },
    {
        name : "AdminPaymentLost",
        url : "/AdminPaymentLost",
        idx : adminIdx++,
        page : <AdminPaymentLost/>
    },
    {
        name : "창꼬실용음악학원",
        url : "/AdminPractice",
        idx : adminIdx++,
        page : <AdminPractice/>
    },
    {
        name : "게시판",
        url : "/AdminEventList",
        idx : adminIdx++,
        page : <AdminEventBoard/>
    },
    {
        name : "게시판 쓰기",
        url : "/AdminEventWrite",
        idx : adminIdx++,
        page : <AdminEventWrite/>
    },
    {
        name : "월별 평가",
        url : "/AdminExam",
        idx : adminIdx++,
        page : <AdminExam/>
    },
    {
        name : "월별 평가 등록",
        url : "/WriteAdminExam",
        idx : adminIdx++,
        page : <WriteAdminExam/>
    },
    {
        name : "창꼬엠실용음악학원",
        url : "/AdminPractice2",
        idx : adminIdx++,
        page : <AdminPractice2/>
    },
    {
        name : "오디션",
        url : "/AdminAudition",
        idx : adminIdx++,
        page : <AdminAudition/>
    },
    {
        name : "오디션 등록",
        url : "/WriteAdminAudition",
        idx : adminIdx++,
        page : <WriteAdminAudition/>
    },

    
]
adminPages.forEach(element => {
    element.route = <Route key={"adminPages"+element.idx} path={element.url} element={element.page}></Route>
});
//----------------------------------- admin

//----------------------------------- etc

let etcIdx = 0
let etcPages = [
    {
        name : "개인정보처리방침",
        url : "/Privacy",
        idx : etcIdx++,
        page : <Privacy/>
    },
    {
        name : "서비스 이용안내",
        url : "/Service",
        idx : etcIdx++,
        page : <Service/>
    },
    {
        name : "testPage",
        url : "/devTest",
        idx : etcIdx++,
        page : <Test/>
    },
    {
        name : "개인정보처리방침 상세",
        url : "/PrivacyPage",
        idx : etcIdx++,
        page : <PrivacyPage/>
    },
    {
        name : "서비스이용약관 상세",
        url : "/ServicePage",
        idx : etcIdx++,
        page : <ServicePage/>
    },
   
]
etcPages.forEach(element => {
    element.route = <Route key={"etcPages"+element.idx} path={element.url} element={element.page}></Route>
});

//----------------------------------- etc


export let pageCollection = {
    GeneralPages : GeneralPages,
    GeneralDetailPages : GeneralDetailPages,
    ManagementMemberPages : ManagementMemberPages,
    adminPages : adminPages,
    etcPages : etcPages,
}

