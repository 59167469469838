import { Checkbox } from "@material-tailwind/react";
import { useState } from "react";
import { deliveryStateStruct } from "../../Components/Common/dataStruct";
import { customAxios } from "../Common/customAxios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx"
import dayjs from "dayjs";

const TableAdminPayment = (props) => {

    const data = props.TABLE_DATA
    const [allCheck, setAllCheck] = useState(false)
    const [checkBoxStates, setCheckBoxStates] = useState()
    const [searchParams, setSearchParams] = useSearchParams()

    const limit = Number(searchParams.get("limit"))
    const active = Number(searchParams.get("active"))

    useEffect(() => {
        if (data)
            onClickAllcheck(false)
    }, [limit, active])

    const onChangeCheckbox = (e) => {
        const change = checkBoxStates.map((element, index) => {
            if (Number(e.target.value) === index) {
                return element = !element
            }
            else {
                return element
            }
        })

        setCheckBoxStates(change)
    }

    const onClickAllcheck = (value) => {
        setAllCheck(value)

        const change = checkBoxStates.map((element) => {
            return element = value
        })
        setCheckBoxStates(change)
    }

    const onChangeAllCheckbox = (e) => {
        onClickAllcheck(!allCheck)
    }
    const getCheckbox = () => {
        const checkList = []
        for (let i = 0; i < checkBoxStates.length; i++) {
            if (checkBoxStates[i]) {
                checkList.push(data[i].Payment_Index)
            }
        }
        return checkList
    }
    const canceledCheck = () => {
        for (let i = 0; i < checkBoxStates.length; i++) {
            if (checkBoxStates[i]) {
                if (data[i].Payment_DeliveryState === "canceled")
                    return true
            }
        }
        return false
    }
    const onClickExcel = () => {


        const today = dayjs(new Date()).format("YYYY-MM-DD")
        const today_Time = dayjs(new Date()).format("YYYY-MM-DD_HHmmssSSS")

        const excelData = []

        for (let i = 0; i < checkBoxStates.length; i++) {
            if (checkBoxStates[i]) {
                const rowData = getRowData(data[i])
                const changeData = changeRow(rowData)
                excelData.push(changeData)
            }
        }

        if (excelData.length < 1) {
            alert("주문이 선택되지 않았습니다.")
            return
        }

        const workSheet = XLSX.utils.json_to_sheet(excelData)
        workSheet['!cols'] = [
            { wpx: 50 },
            { wpx: 50 },
            { wpx: 150 },
            { wpx: 200 },
            { wpx: 75 },
            { wpx: 200 },
            { wpx: 100 },
            { wpx: 400 },
            { wpx: 400 },
            { wpx: 400 },
            { wpx: 75 },
            { wpx: 200 },
            { wpx: 100 },
            { wpx: 100 },
        ]
        const workBook = XLSX.utils.book_new()


        XLSX.utils.book_append_sheet(workBook, workSheet, today)
        XLSX.writeFile(workBook, today_Time + ".xlsx", { compression: true })

    }

    const getRowData = (element) => {
        const products = element.Payment_Custom.products

        let deliveryStateLabel;
        deliveryStateStruct.forEach((state) => {

            if (element.Payment_DeliveryState === state.value) {
                deliveryStateLabel = state.label
            }

        })

        let productName = "";
        let productQuantity = 0;
        products.forEach((product) => {
            productName += `${product.Product_Name}\n`
            productQuantity += product.selectProductQuantity
        })

        const rowData = {
            Payment_Index: element.Payment_Index,
            Payment_DeliveryState: deliveryStateLabel,
            Payment_Date: element.Payment_Date,
            productName: productName,
            productQuantity: productQuantity,
            Delivery_PostName: element.Payment_Custom.Delivery_PostName,
            Delivery_Number: element.Payment_Custom.Delivery_Number,
            Delivery_Postcode: element.Payment_Custom.Delivery_Postcode,
            Delivery_Address: element.Payment_Custom.Delivery_Address + ", " + element.Payment_Custom.Delivery_Detail,
            Delivery_RoadAddress: element.Payment_Custom.Delivery_RoadAddress + ", " + element.Payment_Custom.Delivery_Detail,
            Delivery_Requests: element.Payment_Custom.Delivery_Requests,
            buyer_name: element.Payment_Buyer.buyer_name,
            buyer_tel: element.Payment_Buyer.buyer_tel,
            Member_Nickname: element.Member_Nickname,
            buyer_email: element.Payment_Buyer.buyer_email,
            // buyer_addr : element.Payment_Buyer.buyer_addr,
            // buyer_postcode : element.Payment_Buyer.buyer_postcode,
        }
        return rowData
    }
    const changeRow = (row) => {

        const rowData = {
            "주문 번호": row.Payment_Index,
            "주문 상태": row.Payment_DeliveryState,
            "주문 시간": row.Payment_Date,
            "상품 이름": row.productName,
            "수량": row.productQuantity,
            "배송지 이름": row.Delivery_PostName,
            "배송지 번호": row.Delivery_Number,
            "배송지 우편번호": row.Delivery_Postcode,
            "배송지 주소": row.Delivery_Address,
            "배송지 도로명주소": row.Delivery_RoadAddress,
            "배송지 요청사항": row.Delivery_Requests,
            "구매자 이름": row.buyer_name,
            "구매자 번호": row.buyer_tel,
            "구매자 닉네임": row.Member_Nickname,
            "구매자 이메일": row.buyer_email,
        }
        return rowData
    }


    const onClickChangeState = async (value) => {

        const checkList = getCheckbox()
        const canceld = canceledCheck()

        if (canceld) {
            alert("환불 상태는 변경이 불가능합니다.")
            return
        }

        if (checkList.length <= 0) {
            alert("주문이 선택되지 않았습니다.")
            return
        }
        if (window.confirm("주문 상태를 변경하시겠습니까?\n(환불은 추후 변경이 불가능합니다.)")) {
            const result = await customAxios.post("/api/setPayment", {
                checkList: checkList,
                deliveryState: value
            })

            if (result.data.state === 100) {
                props.setPaymentListRefresh(true)
            }
            else {
                
                alert("주문 상태 변경에 실패하였습니다.")
            }
        }
    }

    if (data) {

        const thClass = "border border-black bg-blue-gray-50 p-2"
        const tdClass = "border text-center ml-1 mr-1"

        let rows = [];

        if (checkBoxStates === undefined || checkBoxStates.length !== data.length) {
            let states = []
            data.forEach(() => {
                states.push(false)
            })
            setCheckBoxStates(states)
            setAllCheck(false)
        }
        else {
            rows = data.map((element, index) => {

                let deliveryStateLabel;
                let deliveryStateClassName;
                deliveryStateStruct.forEach((state) => {

                    if (element.Payment_DeliveryState === state.value) {
                        deliveryStateLabel = state.label
                        deliveryStateClassName = state.className
                    }

                })

                const rowData = getRowData(element)
                const rowTd = Object.entries(rowData).map(([key, value]) => {
                    if (key === "Payment_DeliveryState") {
                        return <td key={"td" + key} className={tdClass + " " + deliveryStateClassName}>{value}</td>
                    }
                    else {
                        return <td key={"td" + key} className={tdClass}>{value}</td>
                    }
                })
                // rowData.id= element.Payment_Index,

                const row = (
                    <tr key={"rows" + index}>
                        <td key="checkbox" className={tdClass}>
                            <Checkbox
                                id={rowData.Payment_Index}
                                value={index}
                                checked={checkBoxStates[index]}
                                onChange={onChangeCheckbox} />
                        </td >
                        {rowTd}
                    </tr>
                )
                return row
            })
        }
        return (
            <div>
                <div className='overflow-x-auto overflow-y-hidden'>
                    <table className="table-fixed whitespace-nowrap ">
                        <thead className="border-b ">
                            <tr>
                                <th className={thClass}><Checkbox checked={allCheck} onChange={onChangeAllCheckbox} /></th>
                                <th className={thClass}>주문 번호</th>
                                <th className={thClass}>주문 상태</th>
                                <th className={thClass}>주문 시간</th>
                                <th className={thClass}>상품 이름</th>
                                <th className={thClass}>수량</th>
                                <th className={thClass}>배송지 이름</th>
                                <th className={thClass}>배송지 번호</th>
                                <th className={thClass}>배송지 우편번호</th>
                                <th className={thClass}>배송지 주소</th>
                                <th className={thClass}>배송지 도로명주소</th>
                                <th className={thClass}>배송지 요청사항</th>
                                <th className={thClass}>구매자 이름</th>
                                <th className={thClass}>구매자 번호</th>
                                <th className={thClass}>구매자 닉네임</th>
                                <th className={thClass}>구매자 이메일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>

                </div>
                <div className="flex ">
                    <button className={deliveryStateStruct[0].className} onClick={(e) => { onClickChangeState(deliveryStateStruct[0].value) }}>{deliveryStateStruct[0].label}</button>
                    <button className={deliveryStateStruct[1].className} onClick={(e) => { onClickChangeState(deliveryStateStruct[1].value) }}>{deliveryStateStruct[1].label}</button>
                    <button className={deliveryStateStruct[2].className} onClick={(e) => { onClickChangeState(deliveryStateStruct[2].value) }}>{deliveryStateStruct[2].label}</button>
                    <button className={deliveryStateStruct[3].className} onClick={(e) => { onClickChangeState(deliveryStateStruct[3].value) }}>{deliveryStateStruct[3].label}</button>
                    <button className={deliveryStateStruct[4].className} onClick={(e) => { onClickChangeState(deliveryStateStruct[4].value) }}>{deliveryStateStruct[4].label}</button>
                    <button className={deliveryStateStruct[5].className} onClick={(e) => { onClickChangeState(deliveryStateStruct[5].value) }}>{deliveryStateStruct[5].label}</button>
                </div>
                <div className="flex mt-3">
                    <button className="bg-green-100" onClick={onClickExcel}>엑셀 다운로드</button>
                </div>
            </div>

        )


    }

}

export default TableAdminPayment




