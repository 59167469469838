import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";
import AuditionDetailCard from "../../Components/Card/AuditionDetailCard";


function AuditionDetail() {
    
    return (
        <div className=''>
            <div className='flex w-full px-2 pt-1 justify-center'>
                <AuditionDetailCard/>
            </div>
            <Footer />
        </div>
    )
}

export default AuditionDetail;
