import { categoryList } from "../Common/dataStruct"

const DisplayTeachClass = ({exam, teachClass, setTeachClass}) => {

    if (exam) {
        const examCategory = JSON.parse(exam.Exam_Category)
        return (
            <div className="">
                <div className="px-2 pb-1">클래스 (반)</div>
                <select className='w-full' value={teachClass} onChange={(e) => { setTeachClass(e.target.value) }}>
                    <option value="">클래스 (반)</option>
                    {categoryList.map((mCategory) => {
                        const index = examCategory.findIndex(category => category === mCategory.value);
                        if (index >= 0) {
                            return (<option key={mCategory.value} value={mCategory.value}>{mCategory.label}</option>)
                        }
                    })}
                </select>
            </div>
        )
    }
}

export default DisplayTeachClass;