import BirthYear from "../Select/BirthYear";


const DisplayBirthYear = ({birthDate, handleDateChange}) => {
    return (
        <div className="">
            <div className="px-2 pb-1">태어난 년도(생년)</div>
            <BirthYear selectedDate={birthDate} onDateChange={handleDateChange} />
        </div>
    )
}

export default DisplayBirthYear;