import { Card, Typography, Button, Dialog, DialogHeader, DialogBody, DialogFooter, } from "@material-tailwind/react";
import { useState } from "react";
import { customAxios } from "../Common/customAxios";

const DialogDefault = (props) => {
  return (
    <Dialog open={props.open} handler={props.handleCancel}>
      <DialogHeader>권한 변경</DialogHeader>
      <DialogBody>
        해당 사용자의 권한을 변경하시겠습니까?
      </DialogBody>
      <DialogFooter>
        <Button onClick={props.handleCancel} className="mr-2 bg-deep-orange-500">
          <span>취소</span>
        </Button>
        <Button onClick={props.handleOK} className="mr-1 bg-blue-gray-500">
          <span>변경</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}


export function MemberTable(props) {
  const [LastOpen, setLastOpen] = useState()
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  }
  const handleOK = () => {
    const setAuth = async () => {
      const result = await customAxios.post("/api/setAuth", {
        Member_UID: LastOpen.element[props.TABLE_ROWS[0]],
        Member_Auth: LastOpen.auth_event.target.value
      })
      if (result.data) {
        console.log(result.data)
      }
    }
    setOpen(false);
    setAuth()
  }
  const handleCancel = () => {
    setOpen(false);
    LastOpen.auth_event.target.value = LastOpen.element[props.TABLE_ROWS[4]]
  }

  const onChangeAuth = (e, element) => {

    let clickedData = {
      auth_event: e,
      element: element
    }
    setLastOpen(clickedData)
    handleOpen()
  }

  return (
    <div>
      <div className='overflow-x-auto overflow-y-hidden'>
        <table className="w-full">
          <thead className="border-b ">
            <tr>
              {props.TABLE_HEAD.map((head) => (
                <th key={head} className="border border-black  bg-blue-gray-50 p-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.TABLE_DATA.map((index) => {
              const isLast = index === props.TABLE_ROWS.length - 1;
              const classes = isLast ? "p-1" : "p-1 border-b border-blue-gray-50";

              let name = ""
              let phone = ""
              if (index["UserInfo"] !== undefined) {
                name = index["UserInfo"].name
                phone = index["UserInfo"].phone
              }

              return (
                <tr key={index[props.TABLE_ROWS[0]]}>
                  <td className={classes}>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {index[props.TABLE_ROWS[0]]}
                    </Typography>
                  </td>
                  <td className={`${classes} bg-blue-gray-50/50`}>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {index[props.TABLE_ROWS[1]]}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {index[props.TABLE_ROWS[2]]}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {name}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {phone}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {index[props.TABLE_ROWS[3]]}
                    </Typography>
                  </td>
                  <td className={classes}>
                    {
                      index[props.TABLE_ROWS[4]] === 'A' ?
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          관리자
                        </Typography>
                        :
                        <select defaultValue={index[props.TABLE_ROWS[4]]} onChange={(e) => { onChangeAuth(e, index) }}>
                          <option value="U">유저</option>
                          <option value="M1">매니저1</option>
                          <option value="M2">매니저2</option>
                          <option value="M3">매니저3(최상위등급)</option>
                          <option value="B">비즈니스</option>
                        </select>
                    }
                  </td>

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <DialogDefault
        open={open}
        handleOpen={handleOpen}
        handleOK={handleOK}
        handleCancel={handleCancel}
      />
    </div>

  );
}