import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../../Components/Common/customAxios";

import { MemberTable } from "../Table/MemberTable";
import { Input, Button } from "@material-tailwind/react"
import { useSelector, useDispatch } from "react-redux";
import { DefaultPagination } from "../../Components/Pagination/DefaultPagination";

function AdminMemberList() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()

    const [memberTable, setMemberTable] = useState([])
    const [memberTableRefresh, setMemberTableRefresh] = useState(true) 
    const [searchNickname, setSearchNickname] = useState("")
    const [searchEmail, setSearchEmail] = useState("")
    const [searchUID, setSearchUID] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchPhone, setSearchPhone] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()
    const [memberCount, setMemberCount] = useState()
    const [searchCount, setSearchCount] = useState()
    const [loadingMember, setLoadingMember] = useState(true)

    const limit = Number(searchParams.get("limit"))
    const active = Number(searchParams.get("active"))

    useEffect(() => {
        searchParams.set("limit", 100)
        searchParams.set("active", 1)
        setSearchParams(searchParams, { replace: true })
        setStart(true)

    }, [])

    useEffect(() => {
        const getMemberCount = async () => {
            if (start) {
                const result = await customAxios.get('/api/getMemberCount')
                if (result.data.state === 101) {
                    setMemberCount(result.data.response)
                }
                else {
                    alert("회원 수를 불러오는데 실패했습니다.")
                }
            }
        }
        const getMemberTable = async () => {
            if (start) {
                setLoadingMember(true)
                const result = await customAxios.get('/api/getMemberTable', {
                    params: {
                        limit: limit,
                        active: active,
                        searchNickname: searchNickname,
                        searchEmail: searchEmail,
                        searchUID: searchUID,
                        searchName: searchName,
                        searchPhone: searchPhone
                    }
                })
                if (result.data.state === 101) {
                    setMemberTable(result.data.response.memberTable)
                    setSearchCount(result.data.response.searchCount)
                }
                else {
                    alert("회원 데이터를 불러오는데 실패했습니다.")
                }
                setLoadingMember(false)
            }
        }

        if (limit === 0) {
            searchParams.set("limit", 100)
            setSearchParams(searchParams, { replace: true })
        }
        if (active === 0) {
            searchParams.set("active", 1)
            setSearchParams(searchParams, { replace: true })
        }

        if(start){
            // console.log("memberTableRefresh:"+memberTableRefresh)
            // console.log("limit:"+limit)
            // console.log("active:"+active)

            if (limit > 0 && active > 0 && memberTableRefresh) {
                getMemberCount()
                getMemberTable()
                setMemberTableRefresh(false)
            }
        }
       

    }, [start, limit, active, memberTableRefresh])


    

    const setActive = (value) => {
        searchParams.set("active", value)
        setSearchParams(searchParams, { replace: true })
        setMemberTableRefresh(true)
    }

    const onClickSearch = () => {
        searchParams.set("active", 1)
        setSearchParams(searchParams, { replace: true })
        setMemberTableRefresh(true)
    }

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickSearch();
        }
    }

    const displayMemberData = () => {
        const searchField =
            (<div key="search" >
                <div>회원 수 : {memberCount}</div>
                <div className="flex flex-row mb-2 gap-4 w-full mt-3" >
                    <div>
                        <Input label="닉네임" value={searchNickname} onChange={(e) => { setSearchNickname(e.target.value) }} onKeyPress={onKeyPress} />
                    </div>
                    <div>
                        <Input label="이메일" value={searchEmail} onChange={(e) => { setSearchEmail(e.target.value) }} onKeyPress={onKeyPress} />
                    </div>
                    <div>
                        <Input label="UID" value={searchUID} onChange={(e) => { setSearchUID(e.target.value) }} onKeyPress={onKeyPress} />
                    </div>
                    <div>
                        <Input label="이름" value={searchName} onChange={(e) => { setSearchName(e.target.value) }} onKeyPress={onKeyPress} />
                    </div>
                    <div>
                        <Input label="번호" value={searchPhone} onChange={(e) => { setSearchPhone(e.target.value) }} onKeyPress={onKeyPress} />
                    </div>
                    <div>
                        <Button key={"refresh"} variant="outlined" className="flex items-center gap-3" onClick={onClickSearch}>검색</Button>
                    </div>
                </div>
            </div>)

        if (loadingMember) {
            return (
                <div>
                        {searchField}
                    <div key="no">로딩중...</div>
                </div>
            )
        }
        else {
            if (memberTable.length <= 0) {

                return (
                    <div>
                        {searchField}
                        <div key="no">회원 데이터가 없습니다.</div>
                        <div key="plz">검색 결과가 없습니다.</div>
                    </div>
                )

            }
            else {
                const TABLE_HEAD = ["닉네임", "이메일", "UID", "이름", "번호", "가입날짜", "권한"];
                const TABLE_ROWS = ["Member_Nickname", "Member_Email", "Member_UID", "Member_RegistrationDate", "Member_Auth"];

                return (
                    <div>
                        {searchField}
                        <MemberTable
                            TABLE_HEAD={TABLE_HEAD}
                            TABLE_ROWS={TABLE_ROWS}
                            TABLE_DATA={memberTable}
                            key={"TableStriped"} />
                        <div key={"Length"}>검색된 회원 수 : {memberTable.length}</div>
                        <div className="flex  justify-center">
                            <DefaultPagination key="DefaultPagination" active={active} setActive={setActive} min={1} max={Math.ceil(searchCount / limit)} />
                        </div>
                    </div>
                )
            }
        }

    }

    return (
        <div>
            <section>
                    <div className='mx-auto px-5'>
                        <div className='flex py-10'>
                            {displayMemberData()}
                        </div>
                    </div>
            </section>
        </div>

    );
}

export default AdminMemberList;


