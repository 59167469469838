import { useEffect, useState } from "react";
import { Card, Input, Checkbox, Button, Typography, Textarea, } from "@material-tailwind/react";
import dayjs from "dayjs";

import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import AgreePrivate from "../Checkbox/AgreePrivate";
import NoticeDialog from "../Dialog/NoticeDialog";

import StringInput from "../Input/StringInput";
import DisplayTrainer from "../Input/DisplayTrainer";
import DisplayBirthDay from "../Input/DisplayBirthDay";
import DisplayFileDiv from "../Input/DisplayFileDiv";
import StringPhone from "../Input/StringPhone";
import DisplayTeachClass from "../Input/DisplayTeachClass";
import SimpleWaitDialog from "../Dialog/SimpleWaitDialog";
import RadioOX from "../Radio/RadioOX";

function WriteSignUpExamForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const examUID = searchParams.get('page')
    const [exam, setExam] = useState(null)
    const [trainerList, setTrainerList] = useState(null)
    const [index, setIndex] = useState(null)
    const [name, setName] = useState('');
    const [teacher, setTeacher] = useState('');
    const [birthDate, setBirthDate] = useState({ year: '', month: '', day: '' });
    const [fileList, setFileList] = useState([
        {
            fileCategory: "", //vocal, rap, dance
            fileName: "",
            fileState: "", //URL, FILE, FILE_VIEW
            fileUrl: "", //URL DATA
            fileData: "", //e.target.value FILE DATA
            fileOrigin: "", //e.target.files[0] FILE ORIGIN
            fileHidden: false,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: false,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: true,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: true,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: true,
        },
    ])
    const [teachClass, setTeachClass] = useState("")
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")
    const [photoUpload, setPhotoUpload] = useState("")

    const [agreeState, setAgreeState] = useState([])
    const [wait, setWait] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)

    const signUpExamNumber = searchParams.get('signUpExamNumberCheck')
    const signUpExamName = searchParams.get('signUpExamNameCheck')
    const signUpExamBirth = searchParams.get('signUpExamBirthCheck')

    const onClickOk = () => {
        setOpenModal(false)
        navigate("/ExamDetail?page=" + examUID, { replace: true })
    }

    const handleAgreeState = (states) => {
        setAgreeState(states)
    }
    const onClickFile = (e, url) => {
        window.open(url, "_blank", "noopener, noreferrer");
    }

    useEffect(() => {
        setStart(true)
        window.scrollTo({ top: 0, behavior: 'auto' })
    }, [])

    useEffect(() => {
        const getTrainerList = async () => {
            const res = await customAxios.get('/api/getTrainers')

            if (res.data.state === 101) {
                setTrainerList(res.data.response)
            }
            else {
                console.log(res.data.message)
            }
        }


        const preCheck = async () => {
            const resultCheckExamLimit = await customAxios.get('/api/checkExamLimit' + "?page=" + examUID)
            if (resultCheckExamLimit.data.state === -100) {
                if (resultCheckExamLimit.data.message === "Exam Terminate") {
                    alert("해당 평가는 마감되었습니다.")
                }
                else if (resultCheckExamLimit.data.message === "Exam Limit") {
                    alert("해당 평가는 정원이 초과되었습니다.")
                }
                navigate("/", { replace: true })
            }
            else{
                setExam(resultCheckExamLimit.data.response)
            }
        }

        const getSignUpExam = async () => {
            const signUpExam = await customAxios.post("/api/getSignUpExam", {
                signUpExamNumber : signUpExamNumber,
                signUpExamName : signUpExamName,
                signUpExamBirth : signUpExamBirth,
            })
            console.log(signUpExam.data.message)

            if (signUpExam.data.state !== 101) {

                if(signUpExam.data.message === "Exam Terminate"){
                    alert("해당 평가는 마감되었습니다.")
                    navigate("/", { replace: true })
                }
                else{
                    //개발 테스트 코드
                    // alert("올바르지 않은 접근입니다."+signUpExam.data.message)
                    alert("올바르지 않은 접근입니다.")
                    navigate("/", { replace: true })
                }
            }
            else if (signUpExam.data.state === 101) { //불러오기
                // console.log(signUpExam.data.response)   
                // alert("불러오기")
                searchParams.set("page", signUpExam.data.response.signUpExam.Exam_UID)
                setSearchParams(searchParams, { replace: true })
                
                setIndex(signUpExam.data.response.signUpExam.SignUpExam_Index)
                setName(signUpExam.data.response.signUpExam.SignUpExam_Name)
                setTeacher(signUpExam.data.response.signUpExam.SignUpExam_Teacher)
                setTeachClass(signUpExam.data.response.signUpExam.SignUpExam_Class)
                setBirthDate({
                    year:signUpExam.data.response.signUpExam.SignUpExam_BirthDay.substring(0,4),
                    month:String(Number(signUpExam.data.response.signUpExam.SignUpExam_BirthDay.substring(4,6))),
                    day:String(Number(signUpExam.data.response.signUpExam.SignUpExam_BirthDay.substring(6,8))),}
                )
                setPhone(signUpExam.data.response.signUpExam.SignUpExam_Phone)
                setNote(signUpExam.data.response.signUpExam.SignUpExam_Note)
                setPhotoUpload(signUpExam.data.response.signUpExam.SignUpExam_PhotoUpload)
                for(let i=0; i<signUpExam.data.response.signUpExamFiles.length; i++){
                    const fileData = signUpExam.data.response.signUpExamFiles[i]
                    // console.log(fileData)
                    changeSignUpFile("fileCategory",fileData.SignUpExamFiles_Category,i)
                    changeSignUpFile("fileName",fileData.SignUpExamFiles_Name,i)
                    if(fileData.SignUpExamFiles_State === "FILE"){
                        changeSignUpFile("fileState","URL",i)
                    }
                    else{
                        changeSignUpFile("fileState",fileData.SignUpExamFiles_State,i)
                    }
                    changeSignUpFile("fileUrl",fileData.SignUpExamFiles_URL,i)

                    changeSignUpFile("fileHidden",fileData.SignUpExamFiles_Hidden===0?false:true,i)
                    
                }
            }
        }
     


        if (start) {
            getTrainerList()

          
            if (signUpExamNumber && signUpExamName && signUpExamBirth) {
                getSignUpExam()
            }
            else{
                if (examUID) {
                    preCheck()
                }
            }
        }
    }, [start, examUID, signUpExamNumber, signUpExamName, signUpExamBirth])



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (wait) {
            return
        }
        if (window.confirm('신청 하시겠습니까?')) {
            const formData = new FormData()

            formData.append('index', index)
            formData.append('examUID', examUID)
            formData.append('name', name)
            formData.append('teachClass', teachClass)
            formData.append('teacher', teacher)
            formData.append('birthDate', birthDate.year+birthDate.month.padStart(2, '0')+birthDate.day.padStart(2, '0'))
            formData.append('phone', phone)
            formData.append('note', note)
            formData.append('photoUpload', photoUpload)

            // 파일 리스트 추가
            fileList.forEach((file) => {
                if (!file.fileHidden && file.fileOrigin && file.fileName) { // 숨겨진 파일은 제외
                    formData.append('files', file.fileOrigin, file.fileName);
                }
            });
            formData.append('fileList', JSON.stringify(fileList))

            setWait(true)
            const writeSignUpExam = await customAxios.post('/api/writeSignUpExam', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            if(writeSignUpExam.data.state === 101) {
                alert("신청이 완료되었습니다.")
                setModalData(writeSignUpExam.data.res)
                setOpenModal(true)
                // navigate('/', { replace: true })
            } else {
                let failMessage = ""
                if(writeSignUpExam.data.message === "Name Failed") {
                    failMessage = "이름을 입력해주세요."
                } 
                else if(writeSignUpExam.data.message === "TeachClass Failed") {
                    failMessage = "클래스 (반)을 입력해주세요."
                } 
                else if(writeSignUpExam.data.message === "Teacher Failed") {
                    failMessage = "선생님을 입력해주세요."
                } 
                else if(writeSignUpExam.data.message === "BirthDay Failed") {
                    failMessage = "생년월일을 입력해주세요."
                } 
                else if(writeSignUpExam.data.message === "Phone Failed") {
                    failMessage = "연락처를 입력해주세요."
                } 
                else if(writeSignUpExam.data.message === "PhotoUpload Failed") {
                    failMessage = "사진/영상 촬영 후 게시 동의 여부를 입력해주세요."
                }
                else if(writeSignUpExam.data.message === "All Hidden Failed") {
                    failMessage = "음원이 올바르게 업로드 되지 않았습니다.\n새로고침 후, 다시 시도해주세요."
                } 
                else if(writeSignUpExam.data.message === "URL Blank Failed") {
                    failMessage = "음원 URL이 올바르지 않습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                } 
                else if(writeSignUpExam.data.message === "File Count Failed") {
                    failMessage = "음원 파일이 올바르지 않습니다."
                } 
                else if(writeSignUpExam.data.message === "Category Blank Failed") {
                    failMessage = "음원 평가 분야가 설정되지 않았습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                } 
                else if(writeSignUpExam.data.message === "Name Blank Failed") {
                    failMessage = "음원 평가곡 제목이 설정되지 않았습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                } 
                else if(writeSignUpExam.data.message === "State Blank Failed") {
                    failMessage = "음원 형식이 설정되지 않았습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                }
                else if(writeSignUpExam.data.message === "GoogleDriveUpload Failed") {
                    failMessage = "파일 업로드에 실패하였습니다. 다시 시도해주세요."
                }
                else if(writeSignUpExam.data.message === "Exam Terminate") {
                    failMessage = "해당 평가는 마감되었습니다."
                }
                else if(writeSignUpExam.data.message === "Exam Limit") {
                    failMessage = "해당 평가는 정원이 초과되었습니다."
                }
                else if(writeSignUpExam.data.message === "auditionClass Failed") {
                    failMessage = "오디션 클래스 신청자는 보컬, 댄스 각 1개 이상 필수적으로 신청해야합니다."
                }
                else {
                    failMessage = ""
                }

                //개발 테스트 코드
                // alert('신청에 실패했습니다.\n'+failMessage+'\n'+writeSignUpExam.data.message)
                alert('신청에 실패했습니다.\n'+failMessage)
            }
            setWait(false)
        }
    };


   

    const handleNameChange = (value) => {
        setName(value)
    }

    const handleDateChange = (date) => {
        setBirthDate(date);
    };
    const changeSignUpFile = (valueName, value, index) => {

        const list = [...fileList]

        if(valueName === "fileCategory"){
            list[index].fileCategory = value
        }
        else if(valueName === "fileName"){
            list[index].fileName = value
        }
        else if(valueName === "fileState"){
            list[index].fileData = ""
            list[index].fileState = value

        }
        else if(valueName === "fileUrl"){
            list[index].fileUrl = value
        }
        else if(valueName === "fileData"){
            list[index].fileData = value
        }
        else if(valueName === "fileOrigin"){
            list[index].fileOrigin = value
        }
        else if(valueName === "fileHidden"){
            list[index].fileHidden = value
        }

        setFileList(list)
    }

    const handlePhoneChange = (value) => {
        setPhone(value)
    }
    const handleNoteChange = (value) => {
            let tmp = value.replace("[", "")
            tmp = tmp.replace("]", "")
            tmp = tmp.replace("\"", "")
            tmp = tmp.replace("\'", "")
            tmp = tmp.replace("\\", "")
            setNote(tmp)
    }
    const handlePhotoUploadRadioChange = (value) => {
        setPhotoUpload(value)
    }
    return (
        <section className="w-full p-2 max-w-md ">
            <SimpleWaitDialog open={wait} />
            <article className='main-visual_wrap'>
                <Card className="pt-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col gap-3">

                            <StringInput value={name} onChange={handleNameChange} label="이름" placeholder="이름을 입력해주세요." />
                            <DisplayTeachClass exam={exam} teachClass={teachClass} setTeachClass={setTeachClass} />
                            <DisplayTrainer trainerList={trainerList} teacher={teacher} setTeacher={setTeacher} />
                            <DisplayBirthDay birthDate={birthDate} handleDateChange={handleDateChange} />
                            <DisplayFileDiv fileList={fileList} setFileList={setFileList} changeSignUpFile={changeSignUpFile} mode={"exam"} teachClass={teachClass} />

                            <StringPhone value={phone} onChange={handlePhoneChange} label="연락처" placeholder="010-xxxx-xxxx" />
                            <StringInput value={note} onChange={handleNoteChange} label="비고" placeholder="댄스 음원 1:30 부터 재생" />

                            <RadioOX radio={photoUpload} label="사진/영상 촬영 후 게시 동의 여부" name="photoUpload" onRadioChange={handlePhotoUploadRadioChange} />

                            <AgreePrivate onStateChange={handleAgreeState} />
                        </div>
                        <div className="flex justify-end">
                            <Button type="submit" variant="outlined" className="rounded bg-Cgcolor text-white" > 신청하기 </Button>
                        </div>
                    </form>
                </Card>
            </article>
            <NoticeDialog open={openModal} onClickOk={onClickOk} modalData={modalData} />
        </section>
    )
}

export default WriteSignUpExamForm;
