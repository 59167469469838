import BirthDay from "../Select/BirthDay";


const DisplayBirthDay = ({birthDate, handleDateChange}) => {
    return (
        <div className="">
            <div className="px-2 pb-1">생년월일</div>
            <BirthDay selectedDate={birthDate} onDateChange={handleDateChange} />
        </div>
    )
}

export default DisplayBirthDay;