import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

import { pageCollection } from '../Page/PageCollection';

const normalMenu = 'px-3 py-2 rounded-md text-sm font-bold duration-300 hover:bg-gray-200 '
const selectMenu = 'px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white'

export default function AdminHeader(props) {

  const [mode, setMode] = useState(props.mode)
  const location = useLocation()

  let subMenu

  switch (mode) {
    case 0:
      subMenu = (
        <div key={"subMenu"} className="flex flex-col items-center bg-blue-gray-100 p-2 w-fit h-fit text-nowrap">
          <div className='font-bold text-lg'>회원 관리 </div>
          <Link className={normalMenu} to={pageCollection.adminPages[1].url}>{pageCollection.adminPages[1].name}</Link>
        </div>
      )
      break;
    case 1:
      subMenu = (
        <div key={"subMenu"} className="flex flex-col items-center bg-blue-gray-100 p-2 w-fit h-fit text-nowrap">
          <div className='font-bold text-lg'> 스토어 관리</div>
          <Link className={normalMenu} to={pageCollection.adminPages[2].url}>{pageCollection.adminPages[2].name}</Link>
          <Link className={normalMenu} to={pageCollection.adminPages[3].url}>{pageCollection.adminPages[3].name}</Link>
          <Link className={normalMenu} to={pageCollection.adminPages[4].url}>{pageCollection.adminPages[4].name}</Link>
          <Link className={normalMenu} to={pageCollection.adminPages[5].url}>{pageCollection.adminPages[5].name}</Link>
        </div>
      )
      break;
    case 2:
      subMenu = (
        <div key={"subMenu"} className="flex flex-col items-center bg-blue-gray-100 p-2 w-fit h-fit text-nowrap">
          <div className='font-bold text-lg'>연습실 관리 </div>
          <Link className={normalMenu} to={pageCollection.adminPages[9].url}>{pageCollection.adminPages[9].name}</Link>
          <Link className={normalMenu} to={pageCollection.adminPages[14].url}>{pageCollection.adminPages[14].name}</Link>
        </div>
      )
      break;
    case 3:
      subMenu = (
        <div key={"subMenu"} className="flex flex-col items-center bg-blue-gray-100 p-2 w-fit h-fit text-nowrap">
          <div className='font-bold text-lg'>게시판 관리 </div>
          <Link className={normalMenu} to={pageCollection.adminPages[10].url}>{pageCollection.adminPages[10].name}</Link>
        </div>
      )
      break;
      case 4:
      subMenu = (
        <div key={"subMenu"} className="flex flex-col items-center bg-blue-gray-100 p-2 w-fit h-fit text-nowrap">
          <div className='font-bold text-lg'>평가 관리 </div>
          <Link className={normalMenu} to={pageCollection.adminPages[12].url}>{pageCollection.adminPages[12].name}</Link>
          <Link className={normalMenu} to={pageCollection.adminPages[15].url}>{pageCollection.adminPages[15].name}</Link>
        </div>
      )
      break;
    default:
      break;
  }



  return (
    <div className='flex bg-gray-100 h-full'>
      <div className="flex flex-col bg-gray-300 w-28">
        <div className='text-lg text-center p-2 font-bold'> Admin </div>
        <button className={mode === 0 ? selectMenu : normalMenu} onClick={(e) => { setMode(0) }}>회원 관리</button>
        <button className={mode === 1 ? selectMenu : normalMenu} onClick={(e) => { setMode(1) }}>스토어 관리</button>
        <button className={mode === 2 ? selectMenu : normalMenu} onClick={(e) => { setMode(2) }}>연습실 관리</button>
        <button className={mode === 3 ? selectMenu : normalMenu} onClick={(e) => { setMode(3) }}>게시판 관리</button>
        <button className={mode === 4 ? selectMenu : normalMenu} onClick={(e) => { setMode(4) }}>평가 관리</button>
      </div>
      {subMenu}
    </div>
  )
}
