
const StringPhone = ({value, label, onChange, placeholder,maxLength=255}) => {

    const formatPhoneNumber = (input) => {
        // 정규식을 사용하여 숫자만 남기고 나머지 문자 제거
        const cleaned = input.replace(/\D/g, '');
        
        // 전화번호에 하이픈 추가
        const formatted = cleaned.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
        
        return formatted;
      };
    
      const checkPhone = () => {
        // 전화번호 정규식
        const phoneNumberRegex = /^\d{2,3}-\d{3,4}-\d{4}$/;
    
        const formattedPhoneNumber = formatPhoneNumber(value);
    
        onChange(formattedPhoneNumber);
        return phoneNumberRegex.test(formattedPhoneNumber)
      }

    return (
    <div className="w-full">
        <div className="px-2 pb-1">{label}</div>
        <input
            className="w-full"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={checkPhone}
            maxLength={maxLength}
        />
    </div>
    )
}

export default StringPhone;