
const StringInput = ({value, label, onChange, placeholder,maxLength=255}) => {
    return (
    <div className="w-full">
        <div className="px-2 pb-1">{label}</div>
        <input
            className="w-full"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            maxLength={maxLength}
        />
    </div>
    )
}

export default StringInput;