import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';



export default function SimpleWaitDialog(props) {

  const [message, setMessage] = useState("잠시만 기다려주세요.")
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {

    if(seconds <=1){
      setMessage("잠시만 기다려주세요.")
    }
    else if(seconds <= 2){
      setMessage("잠시만 기다려주세요..")
    }
    else if(seconds <= 3){
      setMessage("잠시만 기다려주세요...")
    }
    else if(seconds <= 4){
      setMessage("잠시만 기다려주세요....")
    }
    else if(seconds <= 5){
      setMessage("잠시만 기다려주세요.....")
    }
    else if(seconds <= 6){
      setMessage("데이터 처리중입니다.")
    }
    else if(seconds <= 7){
      setMessage("데이터 처리중입니다..")
    }
    else if(seconds <= 8){
      setMessage("데이터 처리중입니다...")
    }
    else if(seconds <= 9){
      setMessage("데이터 처리중입니다....")
    }
    else if(seconds <= 10){
      setMessage("데이터 처리중입니다.....")
    }
    else if(seconds <= 11){
      setSeconds(0)
    }
    const stopWatch = setInterval(() => {
      setSeconds(seconds+1)
    }, 1000);

    return () => clearInterval(stopWatch);

}, [seconds,message]);

    const onClickNo = () =>{

      if(props.WaitDialogClose)
        props.WaitDialogClose()    
    }


  return (
    <div>
      <Dialog className='' open={props.open} >
        <DialogContent className='w-full flex flex-col items-center gap-2'>
          <div>
             {message}
          </div>
            <CircularProgress key="circle" />
        </DialogContent>
      </Dialog>
    </div>
  );
}