import { useEffect, useState } from "react";
import { Card, Input, Checkbox, Button, Typography, Textarea, } from "@material-tailwind/react";
import dayjs from "dayjs";

import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import BirthYear from "../Select/BirthYear";

function CheckSignUpAudtionForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const examUID = searchParams.get('page')

    const [signUpAuditionNumber, setSignUpAuditionNumber] = useState("")
    const [signUpAuditionName, setSignUpAuditionName] = useState("")
    const [signUpAuditionBirth, setSignUpAuditionBirth] = useState("");

    const handleDateChange = (date) => {
        setSignUpAuditionBirth(date);
    };
    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        if (start) {
        }
    }, [start])

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (signUpAuditionNumber === "") {
            alert("참가번호를 입력해주세요.")
            return
        }

        if (signUpAuditionName === "") {
            alert("이름을 입력해주세요.")
            return
        }
        // console.log(selectedDate)
        if (signUpAuditionBirth === "") {
            alert("태어난 년도 (생년)을 선택해주세요.")
            return
        }
        console.log(signUpAuditionNumber,signUpAuditionName,signUpAuditionBirth.year)
      
        if (window.confirm('참가 정보를 수정 하시겠습니까?')) {
            const getSignUpAudition = await customAxios.post("/api/getSignUpAudition", {
                signUpAuditionNumber : signUpAuditionNumber,
                signUpAuditionName : signUpAuditionName,
                signUpAuditionBirth : signUpAuditionBirth.year,
            })

            if(getSignUpAudition.data){
                if(getSignUpAudition.data.state === 101){

                    const url = `/SignUpAudition?signUpAuditionNumberCheck=${signUpAuditionNumber}&signUpAuditionNameCheck=${signUpAuditionName}&signUpAuditionBirthCheck=${signUpAuditionBirth.year}`
                    navigate(url)
                    // console.log(url)
                }
                else{
                    alert("정보가 올바르지 않습니다 다시 시도해주세요.")
                }

            }
            else{
                alert("다시 시도해주세요.")
            }

        }
    };
    const onClickCancel = async (e) => {

        if (signUpAuditionNumber === "") {
            alert("참가번호를 입력해주세요.")
            return
        }

        if (signUpAuditionName === "") {
            alert("이름을 입력해주세요.")
            return
        }
        // console.log(selectedDate)
        if (signUpAuditionBirth === "") {
            alert("태어난 년도 (생년)을 선택해주세요.")
            return
        }

        if (window.confirm('참가를 취소 하시겠습니까?')) {
            const getSignUpAudition = await customAxios.post("/api/getSignUpAudition", {
                signUpAuditionNumber : signUpAuditionNumber,
                signUpAuditionName : signUpAuditionName,
                signUpAuditionBirth : signUpAuditionBirth.year,
            })

            if(getSignUpAudition.data){
                if(getSignUpAudition.data.state === 101){

                    const removeSignUpAudition = await customAxios.post("/api/removeSignUpAudition",{
                        SignUpAudition_Index: getSignUpAudition.data.response.signUpAudition.SignUpAudition_Index
                    })
            
                    if(removeSignUpAudition && removeSignUpAudition.data){
                        if(removeSignUpAudition.data.state === 100){
                            alert("참가 신청이 취소 되었습니다.")
                            navigate("/",{replace:true})
                        }
                        else{
                            alert("신청 취소에 실패하였습니다.\n"+removeSignUpAudition.data.message)
                        }
                    }
                    else{
                        alert("다시 시도해주세요.\n")
                    }
                }
                else{
                    alert("정보가 올바르지 않습니다 다시 시도해주세요.")
                }

            }
            else{
                alert("다시 시도해주세요.")
            }

        }
    };

    return (
        <section className="w-full p-2 max-w-md ">
            <article className='main-visual_wrap'>
                <Card className="pt-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col gap-3">
                            <div className="w-full">
                                <input className="w-full" type="text" placeholder="참가번호"  value={signUpAuditionNumber} onChange={(e) => setSignUpAuditionNumber(e.target.value)} />
                            </div>
                            <div className="w-full">
                                <input className="w-full" type="text" placeholder="이름"  value={signUpAuditionName} onChange={(e) => setSignUpAuditionName(e.target.value)} />
                            </div>
                            <div className="">
                                <div className="px-2">태어난 년도(생년)</div>
                                <BirthYear selectedDate={signUpAuditionBirth} onDateChange={handleDateChange}  />
                            </div>
                        </div>
                        <div className="flex gap-1 justify-end mt-2">
                            <Button type="submit" variant="outlined" className="rounded bg-Cgcolor text-white" > 수정 </Button>
                            <Button variant="outlined" className="rounded bg-Cgcolor text-white" onClick={onClickCancel} > 참가취소 </Button>
                        </div>
                    </form>
                </Card>
            </article>
        </section>
    )
}

export default CheckSignUpAudtionForm;
