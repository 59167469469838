import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function NoticeDialog(props) {

    const onClickOk = () =>{
      if(props.onClickOk)
        props.onClickOk()    
    }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={onClickOk}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='bg-Cgcolor text-white'>
          {props.title}
        </DialogTitle>
        <DialogContent className='mt-3'>
        {props.modalData ===null ? 
          "정보가 올바르지 않습니다. 다시 시도해주세요"  :
          <div className='flex flex-col text-base'>
            <div className='flex gap-2'>
              <div>{"참가번호(수정번호) :"}</div>
              <div>{props.modalData.RD+"_"+props.modalData.signUpAudition_Index+"_"+props.modalData.signUpAudition_RandNum}</div>
            </div>
            <div className='flex gap-2'>
              <div>{"이름 :"}</div>
              <div>{props.modalData.signUpAudition_Name}</div>
            </div>
            <div className='flex gap-2'>
              <div>{"태어난년도(생년) :"}</div>
              <div>{props.modalData.signUpAudition_BirthDay}</div>
            </div>
              <div className='flex gap-2 mt-3 whitespace-pre-line'>
                <div className='whitespace-pre-line'>
                  <div>
                    <label>※ 참가번호(수정번호)를 잊으신 경우 MR 및 내용 수정</label>
                    <span className='text-red-600 font-bold'> 불가</span> 합니다.
                  </div>
                  <div className=''>
                    <span className='text-red-600 font-bold'>캡쳐 </span>
                    후 저장해주세요.
                  </div>
                </div>
              </div>
          </div>
        }
        </DialogContent>
        <DialogActions>
          <div className='py-1'>
            <button className= {props.okBtnClassName} type="button" onClick={onClickOk}> {props.okBtnName} </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
NoticeDialog.defaultProps = {
  title : "신청 정보",
  contents : "Contents",
  okBtnName : "확인",
  okBtnClassName : "border border-Cgcolor rounded-lg py-3 px-3 text-Cgcolor font-bold mr-2",
}