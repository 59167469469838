import { useEffect, useState } from "react";
import { Card, Button } from "@material-tailwind/react";
import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import AgreePrivate from "../Checkbox/AgreePrivate";
import RadioOX from "../Radio/RadioOX";
import StringInput from "../Input/StringInput";
import StringPhone from "../Input/StringPhone";
import DisplayBody from "../Input/DisplayBody";
import DisplayFileDiv from "../Input/DisplayFileDiv";
import DisplayBirthYear from "../Input/DisplayBirthYear";
import SimpleWaitDialog from "../Dialog/SimpleWaitDialog";
import NoticeAuditionDialog from "../Dialog/NoticeAuditionDialog";

function WriteSignUpAuditionForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get('page')

    const [index, setIndex] = useState(null)
    const [name, setName] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [birthDate, setBirthDate] = useState({ year: ''});
    
    const [fileList, setFileList] = useState([
        {
            fileCategory: "", //vocal, rap, dance
            fileName: "",
            fileState: "", //URL, FILE, FILE_VIEW
            fileUrl: "", //URL DATA
            fileData: "", //e.target.value FILE DATA
            fileOrigin: "", //e.target.files[0] FILE ORIGIN
            fileHidden: false,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: false,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: true,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: true,
        },
        {
            fileCategory: "",
            fileName: "",
            fileState: "",
            fileUrl: "",
            fileData: "",
            fileOrigin: "",
            fileHidden: true,
        },
    ])
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")
    const [mentoring, setMentoring] = useState("")
    const [check, setCheck] = useState("")
    const [photoUpload, setPhotoUpload] = useState("")
    const [agreeState, setAgreeState] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [wait, setWait] = useState(false)

    const signUpAuditionNumberCheck = searchParams.get('signUpAuditionNumberCheck')
    const signUpAuditionNameCheck = searchParams.get('signUpAuditionNameCheck')
    const signUpAuditionBirthCheck = searchParams.get('signUpAuditionBirthCheck')




    useEffect(() => {
        setStart(true)
        window.scrollTo({top:0,behavior:'auto'})
    }, [])

    useEffect(() => {

        const preCheck = async () => {
            const response = await customAxios.get('/api/checkAuditionLimit' + "?page=" + page)
            if(response.data.state === -100){
                if(response.data.message === "Audition Terminate"){
                    alert("해당 오디션은 마감되었습니다.")
                }
                else if(response.data.message === "Audition Limit"){
                    alert("해당 오디션은 정원이 초과되었습니다.")
                }
                navigate("/", { replace: true })
            }
        }
        const getSignUpAudition = async () => {
            const res = await customAxios.post("/api/getSignUpAudition", {
                signUpAuditionNumber: signUpAuditionNumberCheck,
                signUpAuditionName: signUpAuditionNameCheck,
                signUpAuditionBirth: signUpAuditionBirthCheck,
            })
            if (res.data.state !== 101) {

                if(res.data.message === "Audition Terminate"){
                    alert("해당 오디션은 마감되었습니다.")
                    navigate("/", { replace: true })
                }
                else{
                    //개발 테스트 코드
                    // alert("올바르지 않은 접근입니다."+res.data.message)
                    alert("올바르지 않은 접근입니다.")
                    navigate("/", { replace: true })
                }
            }
            else if (res.data.state === 101) { //불러오기
                // console.log(res.data.response)
                // alert("불러오기")
                searchParams.set("page", res.data.response.signUpAudition.Audition_UID)
                setSearchParams(searchParams, { replace: true })
                setIndex(res.data.response.signUpAudition.SignUpAudition_Index)
                setName(res.data.response.signUpAudition.SignUpAudition_Name)
                setBirthDate({year:res.data.response.signUpAudition.SignUpAudition_BirthDay})
                setHeight(res.data.response.signUpAudition.SignUpAudition_Height)
                setWeight(res.data.response.signUpAudition.SignUpAudition_Weight)
                setPhone(res.data.response.signUpAudition.SignUpAudition_Phone)
                setNote(res.data.response.signUpAudition.SignUpAudition_Note)
                setMentoring(res.data.response.signUpAudition.SignUpAudition_Mentoring)
                setCheck(res.data.response.signUpAudition.SignUpAudition_Check)
                setPhotoUpload(res.data.response.signUpAudition.SignUpAudition_PhotoUpload)
                for(let i=0; i<res.data.response.signUpAuditionFiles.length; i++){
                    const fileData = res.data.response.signUpAuditionFiles[i]
                    // console.log(fileData)
                    changeSignUpFile("fileCategory",fileData.SignUpAuditionFiles_Category,i)
                    changeSignUpFile("fileName",fileData.SignUpAuditionFiles_Name,i)
                    if(fileData.SignUpAuditionFiles_State === "FILE"){
                        changeSignUpFile("fileState","URL",i)
                    }
                    else{
                        changeSignUpFile("fileState",fileData.SignUpAuditionFiles_State,i)
                    }
                    changeSignUpFile("fileUrl",fileData.SignUpAuditionFiles_URL,i)

                    changeSignUpFile("fileHidden",fileData.SignUpAuditionFiles_Hidden===0?false:true,i)
                    
                }
            }
        }
        if(start){
            if (signUpAuditionNumberCheck && signUpAuditionNameCheck && signUpAuditionBirthCheck) {
                getSignUpAudition()
            }
            else{
                if(page){
                    preCheck()
                }
            } 
           
        }
        
    }, [start, page, signUpAuditionNumberCheck, signUpAuditionNameCheck, signUpAuditionBirthCheck])

    const onClickOk = () => {
        setOpenModal(false)
        navigate("/auditionDetail?page=" + page, { replace: true })
    }

    const handleDateChange = (date) => {
        setBirthDate(date);
    };
    const handleAgreeState = (states) => {
        setAgreeState(states)
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if(wait){
            return
        }

       
        if (window.confirm('신청 하시겠습니까?')) {
            const formData = new FormData()

            formData.append('index', index)
            formData.append('auditionUID', page)
            formData.append('name', name)
            formData.append('height', height)
            formData.append('weight', weight)
            formData.append('birthYear', birthDate.year)
            formData.append('phone', phone)
            formData.append('note', note)
            formData.append('mentoring', mentoring)
            formData.append('check', check)
            formData.append('photoUpload', photoUpload)
            // 파일 리스트 추가
            fileList.forEach((file, index) => {
                if (!file.fileHidden && file.fileOrigin && file.fileName) { // 숨겨진 파일은 제외
                    formData.append('files', file.fileOrigin, file.fileName);
                }
            });
            formData.append('fileList', JSON.stringify(fileList))

            setWait(true)
            const writeSignUpAudition = await customAxios.post('/api/writeSignUpAudition', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            if(writeSignUpAudition.data.state === 101) {
                setWait(false)
                alert("신청이 완료되었습니다.")
                setModalData(writeSignUpAudition.data.res)
                setOpenModal(true)
                // navigate('/', { replace: true })
            } else {
                let failMessage = ""
                if(writeSignUpAudition.data.message === "Name Failed") {
                    failMessage = "이름을 입력해주세요."
                } else if(writeSignUpAudition.data.message === "BirthDay Failed") {
                    failMessage = "태어난 년도(생년)를 입력해주세요."
                } else if(writeSignUpAudition.data.message === "Height Failed") {
                    failMessage = "키를 입력해주세요."
                } else if(writeSignUpAudition.data.message === "Weight Failed") {
                    failMessage = "몸무게를 입력해주세요."
                } else if(writeSignUpAudition.data.message === "Phone Failed") {
                    failMessage = "연락처를 입력해주세요."
                } else if(writeSignUpAudition.data.message === "Mentoring Failed") {
                    failMessage = "사전 멘토링 신청 여부를 입력해주세요."
                } else if(writeSignUpAudition.data.message === "Check Failed") {
                    failMessage = "사전 점검 신청 여부를 입력해주세요."
                } else if(writeSignUpAudition.data.message === "PhotoUpload Failed") {
                    failMessage = "사진/영상 촬영 후 게시 동의 여부를 입력해주세요."
                } else if(writeSignUpAudition.data.message === "All Hidden Failed") {
                    failMessage = "음원이 올바르게 업로드 되지 않았습니다.\n새로고침 후, 다시 시도해주세요."
                } else if(writeSignUpAudition.data.message === "URL Blank Failed") {
                    failMessage = "음원 URL이 올바르지 않습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                } else if(writeSignUpAudition.data.message === "File Count Failed") {
                    failMessage = "음원 파일이 올바르지 않습니다."
                } else if(writeSignUpAudition.data.message === "Category Blank Failed") {
                    failMessage = "음원 신청 분야가 설정되지 않았습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                } else if(writeSignUpAudition.data.message === "Name Blank Failed") {
                    failMessage = "음원 신청곡 제목이 설정되지 않았습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                } else if(writeSignUpAudition.data.message === "State Blank Failed") {
                    failMessage = "음원 형식이 설정되지 않았습니다.\n업로드 하지 않는 음원은 삭제해주세요.\n'-' 버튼 클릭시, 빈 음원파일 삭제 가능합니다."
                }
                else if(writeSignUpAudition.data.message === "GoogleDriveUpload Failed") {
                    failMessage = "파일 업로드에 실패하였습니다. 다시 시도해주세요."
                }
                else if(writeSignUpAudition.data.message === "Audition Terminate") {
                    failMessage = "해당 오디션은 마감되었습니다."
                }
                else if(writeSignUpAudition.data.message === "Audition Limit") {
                    failMessage = "해당 오디션은 정원이 초과되었습니다."
                }
                else {
                    failMessage = ""
                }

                // alert()

                //개발 테스트 코드
                // alert('신청에 실패했습니다.\n'+failMessage+'\n'+writeSignUpAudition.data.message)
                setWait(false)
                alert('신청에 실패했습니다.\n'+failMessage)
            }
            // console.log(response)
        }
    };

    const handleMentoringRadioChange = (value) => {
        setMentoring(value)
    }
    const handleCheckRadioChange = (value) => {
        setCheck(value)
    }
    const handlePhotoUploadRadioChange = (value) => {
        setPhotoUpload(value)
    }

    const handleNameChange = (value) => {
        setName(value)
    }
    const handlePhoneChange = (value) => {
        setPhone(value)
    }
    const handleNoteChange = (value) => {
            let tmp = value.replace("[", "")
            tmp = tmp.replace("]", "")
            tmp = tmp.replace("\"", "")
            tmp = tmp.replace("\'", "")
            tmp = tmp.replace("\\", "")
            setNote(tmp)
    }
    const handleHeightChange = (value) => {
        setHeight(value)
    }
    const handleWeightChange = (value) => {
        setWeight(value)
    }
    const changeSignUpFile = (valueName, value, index) => {

        const list = [...fileList]

        if(valueName === "fileCategory"){
            list[index].fileCategory = value
        }
        else if(valueName === "fileName"){
            list[index].fileName = value
        }
        else if(valueName === "fileState"){
            list[index].fileData = ""
            list[index].fileState = value

        }
        else if(valueName === "fileUrl"){
            list[index].fileUrl = value
        }
        else if(valueName === "fileData"){
            list[index].fileData = value
        }
        else if(valueName === "fileOrigin"){
            list[index].fileOrigin = value
        }
        else if(valueName === "fileHidden"){
            list[index].fileHidden = value
        }

        setFileList(list)
    }
    


    return (
        <section className="w-full p-2 max-w-md ">
            <SimpleWaitDialog open={wait} />
            <article className='main-visual_wrap'>
                <Card className="pt-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col gap-3">
                            <StringInput value={name} onChange={handleNameChange} label="이름" placeholder="이름을 입력해주세요." />    
                            <DisplayBirthYear birthDate={birthDate} handleDateChange={handleDateChange}/>
                            <DisplayBody value1={height} onChange1={handleHeightChange} value2={weight} onChange2={handleWeightChange}/>
                            <label className="mt-1 mb-1">보컬/랩/댄스 택1 (중복 가능)</label>
                            <DisplayFileDiv fileList={fileList} setFileList={setFileList} changeSignUpFile={changeSignUpFile} mode={"audition"} />

                            <StringPhone value={phone} onChange={handlePhoneChange} label="연락처" placeholder="010-xxxx-xxxx" />    
                            <StringInput value={note} onChange={handleNoteChange} label="비고" placeholder="댄스 음원 1:30 부터 재생" />    
                            
                            <RadioOX radio={mentoring} label="사전 멘토링 신청 여부" name="mentoring" onRadioChange={handleMentoringRadioChange} />
                            <RadioOX radio={check} label="사전 점검 신청 여부" name="check" onRadioChange={handleCheckRadioChange} />
                            <RadioOX radio={photoUpload} label="사진/영상 촬영 후 게시 동의 여부" name="photoUpload" onRadioChange={handlePhotoUploadRadioChange} />
                            
                            <AgreePrivate onStateChange={handleAgreeState} />
                        </div>
                        <div className="flex justify-end">
                            <Button type="submit" variant="outlined" className="rounded bg-Cgcolor text-white" > 신청하기 </Button>
                        </div>
                    </form>
                </Card>
            </article>
            <NoticeAuditionDialog open={openModal} onClickOk={onClickOk} modalData={modalData} />
        </section>
    )
}

export default WriteSignUpAuditionForm;
