import { useRef } from "react";

const DisplayFileDiv = ({ fileList, setFileList, changeSignUpFile, mode, teachClass = "" }) => {

    const onClickFile = (e, url) => {
        window.open(url, "_blank", "noopener, noreferrer");
    }




    function isValidURL(url) {
        try {
            new URL(url);
            return true; // 유효한 URL
        } catch (e) {
            return false; // 유효하지 않은 URL
        }
    }

    const DisplayFileList = () => {
        return (
            fileList.map((fileData, index) => {
                const State_Component = <div className="w-full" hidden={fileData.fileState !== ""}>음원 형식을 선택해주세요</div>
                const URL_Component = <input className="w-full" placeholder="음원 URL"
                    hidden={fileData.fileState !== "URL"} value={fileData.fileUrl}
                    onBlur={() => {
                        if (fileData.fileUrl !== "" && !isValidURL(fileData.fileUrl)) {
                            alert("유효하지 않은 URL입니다.")
                            changeSignUpFile("fileUrl", "", index)
                        }
                    }}
                    onChange={(e) => { changeSignUpFile("fileUrl", e.target.value, index) }} />

                const FILE_Component = <input className="w-full" type="file" accept="audio/*,video/*,.m4a,.mp3,.wav,.aac,.mp4,.avi,.webm,.mov"
                    value={fileData.fileData}
                    hidden={fileData.fileState !== "FILE"}
                    multiple={false}
                    onChange={(e) => {
                        if (e.target.files[0] === undefined) {
                            changeSignUpFile("fileData", "", index)
                            changeSignUpFile("fileOrigin", "", index)
                            alert("파일 취소")
                            return
                        }
                        // 다중 파일 선택 확인 및 제한
                        if (e.target.files.length > 1) {
                            changeSignUpFile("fileData", "", index)
                            changeSignUpFile("fileOrigin", "", index)
                            alert("한 번에 하나의 파일만 선택할 수 있습니다.")
                            return
                        }

                        // 파일 유형 검증 추가
                        const file = e.target.files[0];
                        const fileType = file.type;
                        const validAudioTypes = ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/aac', 'audio/m4a', 'audio/mp4', 'audio/x-m4a'];
                        const validVideoTypes = ['video/mp4', 'video/avi', 'video/webm', 'video/quicktime'];

                        // alert(fileType)
                        if (!fileType.startsWith('audio/') && !fileType.startsWith('video/') && !validAudioTypes.includes(fileType) && !validVideoTypes.includes(fileType)) {
                            changeSignUpFile("fileData", "", index)
                            changeSignUpFile("fileOrigin", "", index)
                            alert("오디오 또는 비디오 파일만 업로드 가능합니다.")
                            return
                        }

                        const MB = 100
                        if (e.target.files[0].size > (1024 * 1024 * MB)) {
                            changeSignUpFile("fileData", "", index)
                            changeSignUpFile("fileOrigin", "", index)
                            alert("파일은 " + MB + "MB를 초과할 수 없습니다.")
                            return
                        }
                        changeSignUpFile("fileData", e.target.value, index)
                        changeSignUpFile("fileOrigin", e.target.files[0], index)
                    }} />


                let Category_Component = ""
                let Name_Component = ""
                let options = [];

                if (mode === "exam") {
                    Category_Component = <div className="w-full" hidden={fileData.fileCategory !== ""}>평가 분야를 선택해주세요</div>
                    Name_Component = <input className="w-full" placeholder="평가곡 제목" maxLength={255} value={fileData.fileName}
                        onChange={(e) => { changeSignUpFile("fileName", e.target.value, index) }} />

                    if (teachClass === "vocal" || teachClass === "dance" || teachClass === "audition") { // 오디션
                        options.push(<option key="examOptionAudtion" value="" hidden={true}>평가 분야</option>)
                        options.push(<option key="examOptionVocal" value={"vocal"}>보컬</option>)
                        options.push(<option key="examOptionDance" value={"dance"}>댄스</option>)

                    }
                    else if (teachClass === "entrance") { // 입시
                        options.push(<option key="examOptionEntrance" value="" hidden={true}>평가 분야</option>)
                        options.push(<option key="examOptionSong" value={"song"}>가요</option>)
                        options.push(<option key="examOptionPop" value={"pop"}>팝</option>)
                    }
                    else if (teachClass === "pro") { // 전문
                        options.push(<option key="examOptionPro" value="" hidden={true}>평가 분야</option>)
                        options.push(<option key="examOptionProVocal" value={"vocal"}>보컬</option>)
                    }
                    else {
                        options.push(<option key="examOptionNone" value="" hidden={true}>클래스 선택</option>)

                    }
                }
                else if (mode === "audition") {
                    Category_Component = <div className="w-full" hidden={fileData.fileCategory !== ""}>신청 분야를 선택해주세요</div>
                    Name_Component = <input className="w-full" placeholder="신청곡 제목" maxLength={255} value={fileData.fileName}
                        onChange={(e) => { changeSignUpFile("fileName", e.target.value, index) }} />

                    options.push(<option value="" hidden={true}>신청 분야</option>)
                    options.push(<option value={"vocal"}>보컬</option>)
                    options.push(<option value={"rap"}>랩</option>)
                    options.push(<option value={"dance"}>댄스</option>)
                }

                return (
                    <div className={fileData.fileHidden ? "hidden" : "flex flex-col "} key={"fileDiv" + index}>
                        <div className="flex w-full justify-between items-center">
                            <label key={"fileLabel" + index} className="mt-1 mb-1">음원{index + 1}</label>
                            <button type="button" className={index === 0 ? "hidden" : "w-8 h-8 rounded border-2 border-gray-300  font-bold"} onClick={() => { changeSignUpFile("fileHidden", true, index) }}>-</button>
                        </div>
                        <div className="flex w-full items-center gap-2">
                            <select key={"fileSelect" + index} className="min-w-28 w-32" value={fileData.fileCategory}
                                onChange={(e) => { changeSignUpFile("fileCategory", e.target.value, index) }}>
                                {options}
                            </select>
                            {fileData.fileCategory === "" ? Category_Component : Name_Component}
                        </div>
                        <div className="flex w-full items-center gap-2">
                            <select className="min-w-28 w-32" value={fileData.fileState}
                                onChange={(e) => {
                                    // alert("e.target.value : " + e.target.value)
                                    changeSignUpFile("fileState", e.target.value, index) }}>
                                <option value="" hidden={true}>음원 형식</option>
                                <option value={"URL"}>음원 URL</option>
                                <option value={"FILE"}>음원 파일</option>
                                {/* <option value={"FILE_VIEW"} hidden={true} style={{ display: "none" }}></option> */}
                            </select>
                            {State_Component}
                            {URL_Component}
                            {FILE_Component}
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <div className="flex-col">
            <div className="px-2">
                <label className="text-sm">음원</label>
            </div>
            {/* <div className="px-2">
                <label className="text-xs"> - 음원 파일은 changggo@chang-ggo.com 으로 함께 전송해주세요.</label>
            </div> */}
            <div className="flex flex-col mt-2">
                {DisplayFileList()}
                <div className="flex justify-end">
                    <button className="w-8 h-8 rounded border-2 border-gray-300  font-bold items-end" type="button" onClick={() => {
                        let findIndex = fileList.findIndex(file => file.fileHidden === true)
                        if (findIndex === -1) { alert("최대 5개의 음원을 등록할 수 있습니다."); }
                        else { changeSignUpFile("fileHidden", false, findIndex) }
                    }}>+</button>
                </div>
            </div>
        </div>
    )
}

export default DisplayFileDiv;