import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { customAxios } from "../Common/customAxios"
import dayjs from "dayjs"
import { DefaultPagination } from "../Pagination/DefaultPagination" 
import { Chip } from "@material-tailwind/react"
import { examStateList } from "../Common/dataStruct"
import WaitDialog from "../Dialog/WaitDialog"
// import WaitProgressDialog from "../Dialog/WaitProgressDialog"

function AdminExamList() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()


    const [searchParams, setSearchParams] = useSearchParams()

    const page = Number(searchParams.get("page"))
    const limit = 20

    const [examListRefresh, setExamListRefresh]= useState(true)
    const [examList, setExamList] = useState(null)
    const [length, setLength] = useState(0)
    const [signUpExamList, setSignUpExamList] = useState(null)
    const [dropSignUpExamList, setDropSignUpExamList] = useState(null)
    const [signUpExamListRefresh, setSignUpExamListRefresh]= useState(true)
    const [wait, setWait] = useState(false)

    // const [max, setMax] = useState(0);
    // const [currentValue, setCurrentValue] = useState(0);

    const setPage = (value)=>{
        searchParams.set("page", value)
        setSearchParams(searchParams, { replace: true })
        setExamListRefresh(true)
    }
    const examUID = searchParams.get("UID")
    const setExamUID = (value)=>{
        searchParams.set("UID", value)
        setSearchParams(searchParams, { replace: true })
    }
    

    useEffect(() => {
        setStart(true)
    }, [])

    
    useEffect(() => {

        const getExamList = async () => {
            const result = await customAxios.get("/api/getExamList", {
                params: {
                    page: page,
                    limit: limit,
                }
            })
            if (result) {
                // console.log(result.data.response)
                setExamList(result.data.response.rows)
                setLength(Number(result.data.response.length))
            }
            else {
                alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.")
            }

            setExamListRefresh(false)
        }
    

        if (start) {
            if (page === 0) {
                searchParams.set("page", 1)
                setSearchParams(searchParams, { replace: true })
            }
            else {
                
                if(examListRefresh){
                    getExamList()
                }
            }
        }

    }, [start, page, limit, examListRefresh])

    useEffect(()=>{

        const getSignUpExamList = async () => {
            const getSignUpExamList = await customAxios.get("/api/getSignUpExamList", {params: {UID: examUID,}})
            if (getSignUpExamList && getSignUpExamList.data) {
                if(getSignUpExamList.data.state === 101){
                    // console.log(result.data.response.rows)
                    setSignUpExamList(getSignUpExamList.data.response.rows)
                }
                else{
                    alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.\n["+getSignUpExamList.data.message+"]")
                }
            }
            else {
                alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.")
            }
            const getDropSignUpExamList = await customAxios.get("/api/getDropSignUpExamList", {params: {UID: examUID,}})
            if (getDropSignUpExamList && getDropSignUpExamList.data) {
                if(getDropSignUpExamList.data.state === 101){
                    // console.log(result.data.response.rows)
                    setDropSignUpExamList(getDropSignUpExamList.data.response.rows)
                }
                else{
                    alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.\n["+getDropSignUpExamList.data.message+"]")
                }
            }
            else {
                alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.")
            }
            
            setSignUpExamListRefresh(false)
        }
        
        if(examUID){
            // console.log("ww")
            getSignUpExamList()
        }

    },[examUID,signUpExamListRefresh])

    const onClickExamList = (e, UID)=>{
        setExamListRefresh(true)
        setSignUpExamListRefresh(true)
        setExamUID(UID)
    }

    const onClickExamState = async (e, examData)=>{

        if(examData.Exam_State === "terminated"){

            if(window.confirm("평가를 다시 진행하시겠습니까?\n[다시 참가신청이 가능합니다.]")){
                
                const result = await customAxios.post("/api/updateExamState", {
                    Exam_UID: examData.Exam_UID,
                    Exam_State: "inProgress",}
                )

                if(result){
                    if(result.data && result.data.state === 100){
                        alert("변경이 완료되었습니다.")
                        setExamListRefresh(true)
                    }
                    else{
                        alert("다시 시도해주세요.\n["+result.data.message+"]")
                    }
                }
                else{
                    alert("다시 시도해주세요.")
                }

            }
        }
        else if(examData.Exam_State === "inProgress"){

            if(window.confirm("평가를 마감 하시겠습니까?\n[참가 신청이 불가합니다.]")){

                const result = await customAxios.post("/api/updateExamState", {
                    Exam_UID: examData.Exam_UID,
                    Exam_State: "terminated",}
                )
                if(result){
                // console.log(result.data)

                    if(result.data && result.data.state === 100){
                        alert("변경이 완료되었습니다.")
                        setExamListRefresh(true)
                    }
                    else{
                        alert("다시 시도해주세요.\n["+result.data.message+"]")
                    }
                }
                else{
                    alert("다시 시도해주세요.")
                }

            }
        }
    }


    const onClickExamWrite = (e) => {
        navigate("/WriteAdminExam")
    }

    const onClickExamView = async(e,UID)=>{
        // navigate("/ExamDetail?page="+UID)
        window.open("/ExamDetail?page="+UID, "_blank", "noopener, noreferrer");

    }
    const onClickExamUpdate = async(e,UID)=>{
        navigate("/WriteAdminExam?Exam_UID="+UID)
    }
    const onClickExamDelete = async(e,UID)=>{

        if(window.confirm("해당 평가를 삭제하시겠습니까?")){
            const result = await customAxios.get("/api/removeExam?Exam_UID="+UID)
            if(result){
                if(result.data.state === 100){
                    setExamListRefresh(true)
                    alert("삭제가 완료되었습니다.")
                    if(UID === examUID){
                        searchParams.set("UID", null)
                        setSearchParams(searchParams, { replace: true })
                    }
                }
                else{
                    alert("다시 시도해주세요\n에러 : "+result.data.message)
                }
            }
            else{
                alert("다시 시도해주세요.")
            }
        }
    }

    const displayExamList = () => {

        const pageMin = 1
        const pageMax =  Math.floor(length/limit)+1


        if (examList) {
            return (
                <div className="flex flex-col gap-1 w-full">
                    <label className="mt-1  font-bold">목록</label>
                    <div className="flex w-full gap-2 border bg-Cgcolor text-white p-2">
                        <div className="flex-none w-12 text-center overflow-hidden whitespace-nowrap">상태</div>
                        <div className="flex-none w-7 overflow-hidden whitespace-nowrap ">번호</div>
                        <div className="flex-auto overflow-hidden whitespace-nowrap">제목</div>
                        <div className="w-12 overflow-hidden whitespace-nowrap">제한인원</div>
                        <div className="w-12 overflow-hidden whitespace-nowrap">신청인원</div>
                        <div className="w-24 overflow-hidden whitespace-nowrap">등록일</div>
                        <div className="w-36 overflow-hidden whitespace-nowrap"></div>
                    </div>
                    {examList.map((examData) => {

                        let clickClass = "bg-gray-50"

                        if(examData.Exam_UID === examUID){
                            clickClass = "bg-blue-200"
                        }

                        
                        const state = examStateList.find(state => state.value === examData.Exam_State)
                        
                        const chip = (
                            <Chip className="hover:text-blue-500" color={state.color} value={state.label} />
                        )


                        const DataDayjs = dayjs(examData.Exam_RegistrationDate)
                        return(
                            <div className={clickClass+" flex gap-2 hover:cursor-pointer border text-sm hover:bg-blue-100"} key={examData.Exam_UID} >
                                <div onClick={(e)=>{onClickExamState(e,examData)}} className="flex-none w-14 text-center overflow-hidden">{chip}</div>
                                <div onClick={(e)=>{onClickExamList(e,examData.Exam_UID)}} className="flex-none w-7 text-center overflow-hidden">{examData.Exam_Index}</div>
                                <div onClick={(e)=>{onClickExamList(e,examData.Exam_UID)}} className="flex-auto w-80 truncate">{examData.Exam_Title}</div>
                                <div onClick={(e)=>{onClickExamList(e,examData.Exam_UID)}} className="w-12 text-center overflow-hidden">{examData.Exam_Limit}</div>
                                <div onClick={(e)=>{onClickExamList(e,examData.Exam_UID)}} className="w-12 text-center overflow-hidden">{examData.signUpExam_Length}</div>
                                <div onClick={(e)=>{onClickExamList(e,examData.Exam_UID)}} className="w-24 overflow-hidden whitespace-nowrap">{DataDayjs.format("YYYY-MM-DD")}</div>
                                <div className="flex w-36 gap-2">
                                    <button onClick={(e)=>{onClickExamView(e,examData.Exam_UID)}} className="overflow-hidden whitespace-nowrap rounded-sm bg-Cgcolor text-white" > 보기 </button>
                                    <button onClick={(e)=>{onClickExamUpdate(e,examData.Exam_UID)}} className="overflow-hidden whitespace-nowrap rounded-sm bg-Cgcolor text-white" > 수정 </button>
                                    <button onClick={(e)=>{onClickExamDelete(e,examData.Exam_UID)}} className="overflow-hidden whitespace-nowrap rounded-sm bg-Cgcolor text-white" > 삭제 </button>
                                </div>
                            </div>
                        )
                    })}
                    <div className="flex  justify-center">
                        <DefaultPagination
                            key="DefaultPagination"
                            active={page}
                            setActive={setPage}
                            min={pageMin}
                            max={pageMax}
                        />
                    </div>
                </div>

            )
        }
        else {
            return (
                <div>데이터를 불러오는 중입니다.</div>
            )
        }
    }

    const onClickSignUpExamList = async (e, signUpExam) =>{

        const textField = document.createElement('textarea');
        textField.innerText = signUpExam.rowKey;
        document.body.appendChild(textField);
        textField.select();
        try {
            await navigator.clipboard.writeText(textField.value);
            alert("참가번호 : "+textField.value+"\n클립보드에 복사되었습니다.");
        } catch (err) {
            console.error('클립보드에 복사하는데 실패했습니다.', err);
        }
        textField.remove();
    }
    const onClickSignUpExamURL = (e, signUpExam) =>{
        // console.log(signUpExam.SignUpExam_URL)
        if(signUpExam.SignUpExam_URL){
            window.open(signUpExam.SignUpExam_URL, "_blank", "noopener, noreferrer");
        }
        else{
            alert("해당 참가자의 명단이 아직 저장되지 않았습니다.")
        }
    }
    const onClickSignUpExamUpdate = (e, signUpExam) =>{

        const url = `/SignUpExam?signUpExamNumberCheck=${signUpExam.rowKey}&signUpExamNameCheck=${signUpExam.SignUpExam_Name}&signUpExamBirthCheck=${signUpExam.SignUpExam_BirthDay}`
        // navigate(url)
        window.open(url, "_blank", "noopener, noreferrer");
    }
    const onClickSignUpExamCancel = async (e, signUpExam) =>{

        if(window.confirm("참가 신청을 취소하시겠습니까?")){

            const result = await customAxios.post("/api/removeSignUpExam",{
                SignUpExam_Index: signUpExam.SignUpExam_Index
            })
    
            if(result && result.data){
                if(result.data.state === 100){
                    alert("참가 신청이 취소 되었습니다.")
                    setSignUpExamListRefresh(true)
                    setExamListRefresh(true)
                }
                else{
                    alert("신청 취소에 실패하였습니다.\n"+result.data.message)
                }
    
            }
            else{
                alert("다시 시도해주세요.\n")
            }
        }

    }
    const onClickSignUpExamRecovery = async (e, signUpExam) =>{

        if(window.confirm("해당 신청자를 복구 하시겠습니까?")){

            const result = await customAxios.post("/api/recoverSignUpExam",{
                SignUpExam_Index: signUpExam.SignUpExam_Index
            })
    
            if(result && result.data){
                if(result.data.state === 100){
                    alert("복구 되었습니다.")
                    setExamListRefresh(true)
                    setSignUpExamListRefresh(true)
                }
                else{
                    alert("복구에 실패하였습니다.\n"+result.data.message)
                }
    
            }
            else{
                alert("다시 시도해주세요.\n")
            }
        }

    }

    const onClickSignUpExamListSave = async(e) => {
        if(window.confirm("명단을 스프레드 시트에 저장하시겠습니까?\n[시간이 많이 소요되는 작업입니다.]\n(1명당 약10초)")){
            setWait(true)
            const response = await customAxios.post('/api/uploadSignUpExam', { examUID: examUID });
            if(response.data.state === 100){
                alert("명단이 저장되었습니다.")
                setWait(false)

                // setExamListRefresh(true)
                setSignUpExamListRefresh(true)
            }
            else{
                alert("명단 저장에 실패하였습니다.\n"+response.data.message)
                setWait(false)
            }
        }
    }

    const displaySignUpExamList = () =>{
        
        if(examUID){
            if(signUpExamList&& signUpExamList.length>0){
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 명단</div>
                        <div className="flex w-full gap-2 border bg-Cgcolor text-white p-2">
                            <div className="flex-none w-7 overflow-hidden whitespace-nowrap">번호</div>
                            <div className="-ml-2 w-20 overflow-hidden whitespace-nowrap text-center">이름</div>
                            <div className="-ml-2 w-24 overflow-hidden whitespace-nowrap text-center">생년월일</div>
                            {/* <div className="text-center w-24 overflow-hidden whitespace-nowrap">신청일</div> */}
                            <div className="text-center w-36 overflow-hidden whitespace-nowrap">참가번호(수정번호)</div>
                            <div className="text-center w-36 overflow-hidden whitespace-nowrap">연락처</div>
                        </div>
                        {signUpExamList.map((signUpExam,index)=>{
                            const Rday = dayjs(signUpExam.SignUpExam_RegistrationDate)
                                // onClick={(e)=>onClickSignUpExamList(e,signUpExam)}
                            return (
                                    <div className="flex gap-2 bg-gray-50  hover:cursor-pointer border text-sm hover:bg-blue-100" key={"signUp"+signUpExam.SignUpExam_Index}>
                                        <div  onClick={(e)=>{onClickSignUpExamList(e,signUpExam)}} className="flex-none w-7 text-center">{index+1}</div>
                                        <div  onClick={(e)=>{onClickSignUpExamList(e,signUpExam)}} className="w-20 text-center overflow-hidden whitespace-nowrap">{signUpExam.SignUpExam_Name}</div>
                                        <div  onClick={(e)=>{onClickSignUpExamList(e,signUpExam)}} className="w-24 text-center overflow-hidden whitespace-nowrap">{signUpExam.SignUpExam_BirthDay}</div>
                                        {/* <div  onClick={(e)=>{onClickSignUpExamList(e,signUpExam)}} className="w-24 text-center overflow-hidden whitespace-nowrap">{Rday.format("YYYY-MM-DD")}</div> */}
                                        <div onClick={(e)=>{onClickSignUpExamList(e,signUpExam)}} className="w-40 text-center">{signUpExam.rowKey}</div>
                                        <div onClick={(e)=>{onClickSignUpExamList(e,signUpExam)}} className="w-36 text-center">{signUpExam.SignUpExam_Phone}</div>
                                        <button onClick={(e)=>{onClickSignUpExamURL(e,signUpExam)}} className="rounded bg-Cgcolor text-white w-10 overflow-hidden whitespace-nowrap" >평가</button>
                                        <button onClick={(e)=>{onClickSignUpExamUpdate(e,signUpExam)}} className="rounded bg-Cgcolor text-white w-10 overflow-hidden whitespace-nowrap" >수정</button>
                                        <button onClick={(e)=>{onClickSignUpExamCancel(e,signUpExam)}} className="rounded bg-Cgcolor text-white w-10 overflow-hidden whitespace-nowrap" >취소</button>
                                    </div>
                                
                            )
                        })}
                        <div className="flex w-full justify-end mt-3">
                            <button onClick={onClickSignUpExamListSave} className="rounded bg-green-800 text-white" >명단 저장</button>
                        </div>

                    </div>
                )
            }
            else{
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 명단</div>
                        <div className="w-96">참가자가 없습니다.</div>
                    </div>
                )
            }
                
            

        }
        else{
            return (
                <div className="w-96"> 참가자 리스트를 가져올 평가를 선택해주세요.</div>
            )
        }
        
    }

    const displayDropSignUpExamList = () =>{
        
        if(examUID){
            if(dropSignUpExamList&& dropSignUpExamList.length>0){
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 취소 명단</div>
                        <div className="flex w-full gap-2 border bg-Cgcolor text-white p-2">
                            <div className="flex-none w-7 overflow-hidden whitespace-nowrap">번호</div>
                            <div className="-ml-2 w-20 overflow-hidden whitespace-nowrap text-center">이름</div>
                            <div className="-ml-2 w-24 overflow-hidden whitespace-nowrap text-center">생년월일</div>
                            {/* <div className="text-center w-24 overflow-hidden whitespace-nowrap">신청일</div> */}
                            <div className="text-center w-36 overflow-hidden whitespace-nowrap">참가번호(수정번호)</div>
                        </div>
                        {dropSignUpExamList.map((signUpExam,index)=>{
                            const Rday = dayjs(signUpExam.SignUpExam_RegistrationDate)
                                // onClick={(e)=>onClickSignUpExamList(e,signUpExam)}
                            return (
                                    <div className="flex gap-2 bg-gray-50  hover:cursor-pointer border text-sm hover:bg-blue-100" key={"signUp"+signUpExam.SignUpExam_Index}>
                                        <div  className="flex-none w-7 text-center">{index+1}</div>
                                        <div  className="w-20 text-center overflow-hidden whitespace-nowrap">{signUpExam.SignUpExam_Name}</div>
                                        <div  className="w-24 text-center overflow-hidden whitespace-nowrap">{signUpExam.SignUpExam_BirthDay}</div>
                                        {/* <div  className="w-24 text-center overflow-hidden whitespace-nowrap">{Rday.format("YYYY-MM-DD")}</div> */}
                                        <div className="w-40 text-center">{signUpExam.rowKey}</div>
                                        <button onClick={(e)=>{onClickSignUpExamRecovery(e,signUpExam)}} className="rounded bg-Cgcolor text-white w-10 overflow-hidden whitespace-nowrap" >복구</button>
                                    </div>
                                
                            )
                        })}

                    </div>
                )
            }
            else{
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 취소 명단</div>
                        <div className="w-96">취소자가 없습니다.</div>
                    </div>
                )
            }

        }
    }
    

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (wait) {
                const message = '명단 저장 중입니다. 창을 닫으시면 작업이 중단됩니다.';
                event.returnValue = message; // 크롬 및 다른 브라우저에서 메시지를 표시
                return message; // 일부 브라우저에서 필요
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [wait]); // wait 상태에 따라 effect 재실행

    return (
        <div className="flex w-full">
            <WaitDialog Open={wait} />
            {/* <WaitProgressDialog Open={wait} max={max} currentValue={currentValue} /> */}
            <section className="flex flex-col p-2 w-full gap-2">
                {displayExamList()}
                <div className="flex justify-end">
                    <button onClick={onClickExamWrite} className="rounded bg-Cgcolor text-white" > 등록 </button>
                </div>
            </section>
            <section className="flex flex-col p-2 w-auto gap-2">
                {displaySignUpExamList()}
                {displayDropSignUpExamList()}
            </section>
        </div>
        
    )
}

export default AdminExamList;
