import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../../Components/Common/customAxios";

import AdminHeader from "../../Components/Fixed/AdminHeader";
import Footer from "../../Components/Fixed/Footer";
import TableAdminPayment from "../../Components/Table/TableAdminPayment";
import { DefaultPagination } from "../../Components/Pagination/DefaultPagination";
import { deliveryStateStruct } from "../../Components/Common/dataStruct";
import dayjs from "dayjs";
import { Input } from "@material-tailwind/react";


const AdminPaymentList = () => {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const loginState = useSelector((state) => state.login)
    const [allCount, setAllCount] = useState(0)
    const [paidCount, setPaidCount] = useState(0)
    const [canceledCount, setCanceledCount] = useState(0)
    const [preparingCount, setPreparingCount] = useState(0)
    const [startCount, setStartCount] = useState(0)
    const [inDeliveryCount, setInDeliveryCount] = useState(0)
    const [deliveredCount, setDeliveredCount] = useState(0)
    const [amount, setAmount] = useState(0)
    const [paidAmount, setPaidAmount] = useState(0)
    const [refundAmount, setRefundAmount] = useState(0)
    const [productCount, setProductCount] = useState(0)
    const [paidProductCount, setPaidProductCount] = useState(0)
    const [refundProductCount, setRefundProductCount] = useState(0)

    
    const [paymentList, setPaymentList] = useState()
    const [paymentListRefresh, setPaymentListRefresh] = useState(true)

    const [searchParams, setSearchParams] = useSearchParams()

    let pageMin = 1
    let pageMax = 1

    const limit = Number(searchParams.get("limit"))
    const active = Number(searchParams.get("active"))
    const deliveryState = searchParams.get("deliveryState")
    const startDate = searchParams.get("startDate")
    const endDate = searchParams.get("endDate")

    const [searchProductName, setSearchProductName] = useState("")
    const [searchDeliveryPostName, setSearchDeliveryPostName] = useState("")
    const [searchDeliveryNumber, setSearchDeliveryNumber] = useState("")
    const [searchBuyerName, setSearchBuyerName] = useState("")
    const [searchBuyerNumber, setSearchBuyerNumber] = useState("")
    const [searchBuyerNickname, setSearchBuyerNickname] = useState("")


    const setLimit = (index) => {
        searchParams.set("limit", index)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
        setPaymentListRefresh(true)
    }
    const setActive = (index) => {
        searchParams.set("active", index)
        setSearchParams(searchParams, { replace: true })
        setPaymentListRefresh(true)
    }
    const setDeliveryState = (value) => {
        searchParams.set("deliveryState", value)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
        setPaymentListRefresh(true)
    }
    const setStartDate = (value) => {
        searchParams.set("startDate", value)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
        setPaymentListRefresh(true)
    }
    const setEndDate = (value) => {
        searchParams.set("endDate", value)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
        setPaymentListRefresh(true)
    }

    if (limit > 0) {
        if (allCount % (limit) === 0) {
            pageMax = parseInt(allCount / (limit))
        }
        else {
            pageMax = parseInt(allCount / (limit)) + 1
        }
    }

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getPaymentCount = async () => {
            const result = await customAxios.get("/api/getPaymentCount", {
                params: {
                    active: active,
                    limit: limit,
                    deliveryState: deliveryState,
                    startDate: startDate,
                    endDate: endDate,
                    searchProductName: searchProductName,
                    searchDeliveryPostName: searchDeliveryPostName,
                    searchDeliveryNumber: searchDeliveryNumber,
                    searchBuyerName: searchBuyerName,
                    searchBuyerNumber: searchBuyerNumber,
                    searchBuyerNickname: searchBuyerNickname,
                }
            })
            // console.log(result.data)

            if (result.data.state === 101) {
                setAllCount(result.data.response.allCount)
                setPaidCount(result.data.response.paidCount)
                setCanceledCount(result.data.response.canceledCount)
                setPreparingCount(result.data.response.preparingCount)
                setStartCount(result.data.response.startCount)
                setInDeliveryCount(result.data.response.inDeliveryCount)
                setDeliveredCount(result.data.response.deliveredCount)
                setAmount(result.data.response.amount)
                setPaidAmount(result.data.response.paidAmount)
                setRefundAmount(result.data.response.refundAmount)
                setProductCount(result.data.response.productCount)
                setPaidProductCount(result.data.response.paidProductCount)
                setRefundProductCount(result.data.response.refundProductCount)
            }

        }
        const getPaymentList = async () => {
            const result = await customAxios.get("/api/getPaymentList", {
                params: {
                    active: active,
                    limit: limit,
                    deliveryState: deliveryState,
                    startDate: startDate,
                    endDate: endDate,
                    searchProductName: searchProductName,
                    searchDeliveryPostName: searchDeliveryPostName,
                    searchDeliveryNumber: searchDeliveryNumber,
                    searchBuyerName: searchBuyerName,
                    searchBuyerNumber: searchBuyerNumber,
                    searchBuyerNickname: searchBuyerNickname,
                }
            })
            if (result.data.state === 101) {
                setPaymentList(result.data.response)
            }
            else {
                alert("주문 목록 조회에 실패하였습니다.")
            }
        }

        if (start) {

            if (loginState.isLogin === null) {

            }
            else if (loginState.isLogin === true) {


                if (active === 0) {
                    searchParams.set("active", 1)
                    setSearchParams(searchParams, { replace: true })
                }
                if (limit === 0) {
                    searchParams.set("limit", 100)
                    setSearchParams(searchParams, { replace: true })
                }
                if (deliveryState === null) {
                    searchParams.set("deliveryState", "all")
                    setSearchParams(searchParams, { replace: true })
                }
                if (startDate === null) {
                    searchParams.set("startDate", todayjs.format("YYYY-MM-DD"))
                    setSearchParams(searchParams, { replace: true })
                }
                if (endDate === null) {
                    searchParams.set("endDate", todayjs.format("YYYY-MM-DD"))
                    setSearchParams(searchParams, { replace: true })
                }
                if (paymentListRefresh) {
                // console.log(paymentListRefresh)
                // console.log(active)
                // console.log(limit)
                // console.log(deliveryState)
                // console.log(startDate)
                // console.log(endDate)

                    if (active > 0 && limit > 0 && deliveryState !== null && startDate !== null && endDate !== null) {
                        getPaymentList()
                        getPaymentCount()
                        setPaymentListRefresh(false)
                    }
                }
            }
            else if (loginState.isLogin === false) {
                alert("권한이 없습니다.")
                navigate("/", { replace: true })
            }
        }
    }, [start, loginState.isLogin, loginState.Member_Auth, active, limit, deliveryState, startDate, endDate, paymentListRefresh])

    const normalDeliveryMenu = "bg-gray-500"
    const selectDeliveryMenu = "bg-green-600"

    const todayjs = dayjs(new Date())

    const onClickSearch = () => {
        setPaymentListRefresh(true)
    }


    if (loginState.isLogin) {
        return (
            <div>
                <section>
                    <article className='main-visual_wrap'>
                        <div className='mx-auto  px-5'>
                            <div className='flex flex-col py-10 gap-2'>
                                <div className="flex gap-5 items-center">
                                    <div className="">출력 개수</div>
                                    <div className="flex">
                                        <button className={limit === 100 ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setLimit(100) }}>100</button>
                                        <button className={limit === 200 ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setLimit(200) }}>200</button>
                                        <button className={limit === 300 ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setLimit(300) }}>300</button>
                                    </div>
                                </div>
                                <div className="flex gap-5 items-center">
                                    <div className="">주문일자</div>
                                    {startDate !== null ?
                                        <div className="flex">
                                            <input type="date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} max={todayjs.format("YYYY-MM-DD")} />
                                            <input type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} max={todayjs.format("YYYY-MM-DD")} />
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                setStartDate(todayjs.format("YYYY-MM-DD"))
                                                setEndDate(todayjs.format("YYYY-MM-DD"))
                                            }}>오늘</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                const yesterday = todayjs.subtract(1, "day")
                                                setStartDate(yesterday.format("YYYY-MM-DD"))
                                                setEndDate(yesterday.format("YYYY-MM-DD"))
                                            }}>어제</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                setStartDate(dayjs().day(0).format("YYYY-MM-DD"))
                                                setEndDate(todayjs.format("YYYY-MM-DD"))
                                            }}>이번주</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                setStartDate(dayjs().day(-7).format("YYYY-MM-DD"))
                                                setEndDate(dayjs().day(-1).format("YYYY-MM-DD"))
                                            }}>지난주</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                const startMonth = dayjs().day(0).set("D", 1)
                                                setStartDate(startMonth.format("YYYY-MM-DD"))
                                                setEndDate(todayjs.format("YYYY-MM-DD"))
                                            }}>이번달</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                const startMonth = dayjs().day(0).set("D", 1).subtract(1, "M")
                                                const daysInMonth = startMonth.daysInMonth()
                                                const endMonth = startMonth.set("D", daysInMonth)
                                                setStartDate(startMonth.format("YYYY-MM-DD"))
                                                setEndDate(endMonth.format("YYYY-MM-DD"))
                                            }}>지난달</button>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="flex gap-5 items-center">
                                    <div className="">주문상태</div>
                                    <div className="flex">
                                        <button className={deliveryState === "all" ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setDeliveryState("all") }}>전체</button>
                                        <button className={deliveryState === deliveryStateStruct[0].value ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setDeliveryState(deliveryStateStruct[0].value) }}>{deliveryStateStruct[0].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[1].value ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setDeliveryState(deliveryStateStruct[1].value) }}>{deliveryStateStruct[1].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[2].value ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setDeliveryState(deliveryStateStruct[2].value) }}>{deliveryStateStruct[2].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[3].value ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setDeliveryState(deliveryStateStruct[3].value) }}>{deliveryStateStruct[3].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[4].value ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setDeliveryState(deliveryStateStruct[4].value) }}>{deliveryStateStruct[4].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[5].value ? selectDeliveryMenu : normalDeliveryMenu} onClick={(e) => { setDeliveryState(deliveryStateStruct[5].value) }}>{deliveryStateStruct[5].label}</button>
                                    </div>
                                </div>
                                <div className="flex w-full gap-5 items-center">
                                    <div className="">조건</div>
                                    <div className="">
                                        <Input className="" label="상품 이름" value={searchProductName} onChange={(e) => { setSearchProductName(e.target.value) }} onKeyDown={(e) => {if(e.key === "Enter") {onClickSearch()}}} />
                                    </div>
                                    <div className="">
                                        <Input className="" label="배송지 이름" value={searchDeliveryPostName} onChange={(e) => { setSearchDeliveryPostName(e.target.value) }} onKeyDown={(e) => {if(e.key === "Enter") {onClickSearch()}}} />
                                    </div>
                                    <div className="">
                                        <Input className="" label="배송지 번호" value={searchDeliveryNumber} onChange={(e) => { setSearchDeliveryNumber(e.target.value) }} onKeyDown={(e) => {if(e.key === "Enter") {onClickSearch()}}} />
                                    </div>
                                    <div className="">
                                        <Input className="" label="구매자 이름" value={searchBuyerName} onChange={(e) => { setSearchBuyerName(e.target.value) }} onKeyDown={(e) => {if(e.key === "Enter") {onClickSearch()}}} />
                                    </div>
                                    <div className="">
                                        <Input className="" label="구매자 번호" value={searchBuyerNumber} onChange={(e) => { setSearchBuyerNumber(e.target.value) }} onKeyDown={(e) => {if(e.key === "Enter") {onClickSearch()}}} />
                                    </div>
                                    <div className="">
                                        <Input className="" label="닉네임" value={searchBuyerNickname} onChange={(e) => { setSearchBuyerNickname(e.target.value) }} onKeyDown={(e) => {if(e.key === "Enter") {onClickSearch()}}} />
                                    </div>
                                    <div>
                                        <button className="rounded-md bg-blue-gray-100" onClick={(e) => {onClickSearch()}} >검색</button>
                                    </div>
                                </div>
                                <div className="flex gap-5 items-center">
                                    <div>조건 검색 건수 : {allCount}</div>
                                    <div>결제 완료 : {paidCount}</div>
                                    <div>환불 : {canceledCount}</div>
                                    <div>배송 준비중 : {preparingCount}</div>
                                    <div>배송 시작 : {startCount}</div>
                                    <div>배송 중 : {inDeliveryCount}</div>
                                    <div>배송 완료 : {deliveredCount}</div>
                                </div>
                                <div className="flex gap-5 items-center">
                                    <div>총 결제 금액 : {amount.toLocaleString()}</div>
                                    <div>결제 완료 금액 : {paidAmount.toLocaleString()}</div>
                                    <div>환불 금액 : {refundAmount.toLocaleString()}</div>
                                </div>
                                <div className="flex gap-5 items-center">
                                    <div>총 판매 개수 : {productCount}</div>
                                    <div>결제 완료 개수 : {paidProductCount}</div>
                                    <div>환불 개수 : {refundProductCount}</div>
                                </div>
                                <TableAdminPayment
                                    TABLE_DATA={paymentList}
                                    setPaymentListRefresh={setPaymentListRefresh}
                                />
                               
                                <div className="flex w-full justify-center">
                                    <DefaultPagination
                                        key="DefaultPagination"
                                        active={active}
                                        setActive={setActive}
                                        min={pageMin}
                                        max={pageMax}
                                    />
                                </div>
                            </div>
                        </div>

                    </article>

                </section>

                <Footer />
            </div>
        )
    }
}

export default AdminPaymentList