import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { customAxios } from "../Common/customAxios"
import dayjs from "dayjs"
import { DefaultPagination } from "../Pagination/DefaultPagination" 
import { Chip } from "@material-tailwind/react"
import { examStateList } from "../Common/dataStruct"
import WaitDialog from "../Dialog/WaitDialog"
// import WaitProgressDialog from "../Dialog/WaitProgressDialog"

function AdminAuditionList() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()


    const [searchParams, setSearchParams] = useSearchParams()

    const page = Number(searchParams.get("page"))
    const limit = 20

    const [auditionListRefresh, setAuditionListRefresh]= useState(true)
    const [auditionList, setAuditionList] = useState(null)
    const [length, setLength] = useState(0)
    const [signUpAuditionList, setSignUpAuditionList] = useState(null)
    const [dropsignUpAuditionList, setDropsignUpAuditionList] = useState(null)
    const [signUpAuditionListRefresh, setSignUpAuditionListRefresh]= useState(true)
    const [wait, setWait] = useState(false)

    // const [max, setMax] = useState(0);
    // const [currentValue, setCurrentValue] = useState(0);

    const setPage = (value)=>{
        searchParams.set("page", value)
        setSearchParams(searchParams, { replace: true })
        setAuditionListRefresh(true)
    }
    const auditionUID = searchParams.get("UID")
    const setAuditionUID = (value)=>{
        searchParams.set("UID", value)
        setSearchParams(searchParams, { replace: true })
    }
    

    useEffect(() => {
        setStart(true)
    }, [])

    
    useEffect(() => {

        const getAuditionList = async () => {
            const result = await customAxios.get("/api/getAuditionList", {
                params: {
                    page: page,
                    limit: limit,
                }
            })
            if (result) {
                // console.log(result.data.response)
                setAuditionList(result.data.response.rows)
                setLength(Number(result.data.response.length))
            }
            else {
                alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.")
            }

            setAuditionListRefresh(false)
        }
    

        if (start) {
            if (page === 0) {
                searchParams.set("page", 1)
                setSearchParams(searchParams, { replace: true })
            }
            else {
                
                if(auditionListRefresh){
                    getAuditionList()
                }
            }
        }

    }, [start, page, limit, auditionListRefresh])

    useEffect(()=>{

        const getSignUpAuditionList = async () => {
            const SignUpAuditionList = await customAxios.get("/api/getSignUpAuditionList", {params: {UID: auditionUID,}})
            if (SignUpAuditionList && SignUpAuditionList.data) {
                if(SignUpAuditionList.data.state === 101){
                    // console.log(result.data.response.rows)
                    setSignUpAuditionList(SignUpAuditionList.data.response.rows)
                }
                else{
                    alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.\n["+SignUpAuditionList.data.message+"]")
                }
            }
            else {
                alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.")
            }
            const getDropSignUpAuditionList = await customAxios.get("/api/getDropSignUpAuditionList", {params: {UID: auditionUID,}})
            if (getDropSignUpAuditionList && getDropSignUpAuditionList.data) {
                if(getDropSignUpAuditionList.data.state === 101){
                    // console.log(result.data.response.rows)
                    setDropsignUpAuditionList(getDropSignUpAuditionList.data.response.rows)
                }
                else{
                    alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.\n["+getDropSignUpAuditionList.data.message+"]")
                }
            }
            else {
                alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.")
            }
            
            setSignUpAuditionListRefresh(false)
        }
        
        if(auditionUID){
            // console.log("ww")
            getSignUpAuditionList()
        }

    },[auditionUID,signUpAuditionListRefresh])

    const onClickAuditionList = (e, UID)=>{
        // alert("눌렀당")
        setAuditionListRefresh(true)
        setSignUpAuditionListRefresh(true)
        setAuditionUID(UID)
    }

    const onClickAuditionState = async (e, auditionData)=>{

        if(auditionData.Audition_State === "terminated"){

            if(window.confirm("오디션 참가 신청을 다시 진행하시겠습니까?\n[다시 참가신청이 가능합니다.]")){
                
                const result = await customAxios.post("/api/updateAuditionState", {
                    Audition_UID: auditionData.Audition_UID,
                    Audition_State: "inProgress",}
                )

                if(result){
                    if(result.data && result.data.state === 100){
                        alert("변경이 완료되었습니다.")
                        setAuditionListRefresh(true)
                    }
                    else{
                        alert("다시 시도해주세요.\n["+result.data.message+"]")
                    }
                }
                else{
                    alert("다시 시도해주세요.")
                }

            }
        }
        else if(auditionData.Audition_State === "inProgress"){

            if(window.confirm("오디션 참가 신청을 마감 하시겠습니까?\n[참가 신청이 불가합니다.]")){

                const result = await customAxios.post("/api/updateAuditionState", {
                    Audition_UID: auditionData.Audition_UID,
                    Audition_State: "terminated",}
                )
                if(result){
                // console.log(result.data)

                    if(result.data && result.data.state === 100){
                        alert("변경이 완료되었습니다.")
                        setAuditionListRefresh(true)
                    }
                    else{
                        alert("다시 시도해주세요.\n["+result.data.message+"]")
                    }
                }
                else{
                    alert("다시 시도해주세요.")
                }

            }
        }
    }


    const onClickAuditionWrite = (e) => {
        navigate("/WriteAdminAudition")
    }

    const onClickAuditionView = async(e,UID)=>{
        window.open("/AuditionDetail?page="+UID, "_blank", "noopener, noreferrer");

    }
    const onClickAuditionUpdate = async(e,UID)=>{
        navigate("/WriteAdminAudition?Audition_UID="+UID)
    }
    const onClickAuditionRemove = async(e,UID)=>{

        if(window.confirm("해당 오디션을 삭제하시겠습니까?")){
            const result = await customAxios.get("/api/removeAudition?Audition_UID="+UID)
            if(result){
                if(result.data.state === 100){
                    setAuditionListRefresh(true)
                    alert("삭제가 완료되었습니다.")
                    if(UID === auditionUID){
                        searchParams.set("UID", null)
                        setSearchParams(searchParams, { replace: true })
                    }
                }
                else{
                    alert("다시 시도해주세요\n에러 : "+result.data.message)
                }
            }
            else{
                alert("다시 시도해주세요.")
            }
        }
    }

    const displayAuditionList = () => {

        const pageMin = 1
        const pageMax =  Math.floor(length/limit)+1


        if (auditionList) {
            return (
                <div className="flex flex-col gap-1 w-full">
                    <label className="mt-1  font-bold">목록</label>
                    <div className="flex w-full gap-2 border bg-Cgcolor text-white p-2">
                        <div className="flex-none w-12 text-center overflow-hidden whitespace-nowrap">상태</div>
                        <div className="flex-none w-7 overflow-hidden whitespace-nowrap ">번호</div>
                        <div className="flex-auto overflow-hidden whitespace-nowrap">제목</div>
                        <div className="w-12 overflow-hidden whitespace-nowrap">제한인원</div>
                        <div className="w-12 overflow-hidden whitespace-nowrap">신청인원</div>
                        <div className="w-24 overflow-hidden whitespace-nowrap">등록일</div>
                        <div className="w-36 overflow-hidden whitespace-nowrap"></div>
                    </div>
                    {auditionList.map((auditionData) => {

                        let clickClass = "bg-gray-50"

                        if(auditionData.Audition_UID === auditionUID){
                            clickClass = "bg-blue-200"
                        }

                        
                        const state = examStateList.find(state => state.value === auditionData.Audition_State)
                        
                        const chip = (
                            <Chip className="hover:text-blue-500" color={state.color} value={state.label} />
                        )


                        const DataDayjs = dayjs(auditionData.Audition_RegistrationDate)
                        return(
                            <div className={clickClass+" flex gap-2 hover:cursor-pointer border text-sm hover:bg-blue-100"} key={auditionData.Audition_UID} >
                                <div onClick={(e)=>{onClickAuditionState(e,auditionData)}} className="flex-none w-14 text-center overflow-hidden">{chip}</div>
                                <div onClick={(e)=>{onClickAuditionList(e,auditionData.Audition_UID)}} className="flex-none w-7 text-center overflow-hidden">{auditionData.Audition_Index}</div>
                                <div onClick={(e)=>{onClickAuditionList(e,auditionData.Audition_UID)}} className="flex-auto w-80 truncate">{auditionData.Audition_Title}</div>
                                <div onClick={(e)=>{onClickAuditionList(e,auditionData.Audition_UID)}} className="w-12 text-center overflow-hidden">{auditionData.Audition_Limit}</div>
                                <div onClick={(e)=>{onClickAuditionList(e,auditionData.Audition_UID)}} className="w-12 text-center overflow-hidden">{auditionData.signUpAudition_Length}</div>
                                <div onClick={(e)=>{onClickAuditionList(e,auditionData.Audition_UID)}} className="w-24 overflow-hidden whitespace-nowrap">{DataDayjs.format("YYYY-MM-DD")}</div>
                                <div className="flex w-36 gap-2">
                                    <button onClick={(e)=>{onClickAuditionView(e,auditionData.Audition_UID)}} className="overflow-hidden whitespace-nowrap rounded-sm bg-Cgcolor text-white" > 보기 </button>
                                    <button onClick={(e)=>{onClickAuditionUpdate(e,auditionData.Audition_UID)}} className="overflow-hidden whitespace-nowrap rounded-sm bg-Cgcolor text-white" > 수정 </button>
                                    <button onClick={(e)=>{onClickAuditionRemove(e,auditionData.Audition_UID)}} className="overflow-hidden whitespace-nowrap rounded-sm bg-Cgcolor text-white" > 삭제 </button>
                                </div>
                            </div>
                        )
                    })}
                    <div className="flex  justify-center">
                        <DefaultPagination
                            key="DefaultPagination"
                            active={page}
                            setActive={setPage}
                            min={pageMin}
                            max={pageMax}
                        />
                    </div>
                </div>

            )
        }
        else {
            return (
                <div>데이터를 불러오는 중입니다.</div>
            )
        }
    }

    const onClickSignUpAuditionList = async (e, signUpAudition) => {
        const textField = document.createElement('textarea');
        textField.innerText = signUpAudition.rowKey;
        document.body.appendChild(textField);
        textField.select();
        try {
            await navigator.clipboard.writeText(textField.value);
            alert("참가번호 : "+textField.value+"\n클립보드에 복사되었습니다.");
        } catch (err) {
            console.error('클립보드에 복사하는데 실패했습니다.', err);
        }
        textField.remove();
    }
    const onClickSignUpAuditionUpdate = (e, signUpAudition) =>{

        const url = `/SignUpAudition?signUpAuditionNumberCheck=${signUpAudition.rowKey}&signUpAuditionNameCheck=${signUpAudition.SignUpAudition_Name}&signUpAuditionBirthCheck=${signUpAudition.SignUpAudition_BirthDay}`
        // navigate(url)
        window.open(url, "_blank", "noopener, noreferrer");
    }
    const onClickSignUpExamCancel = async (e, signUpAudition) =>{

        if(window.confirm("참가 신청을 취소하시겠습니까?")){

            const result = await customAxios.post("/api/removeSignUpAudition",{
                SignUpAudition_Index: signUpAudition.SignUpAudition_Index
            })
    
            if(result && result.data){
                if(result.data.state === 100){
                    alert("참가 신청이 취소 되었습니다.")
                    setSignUpAuditionListRefresh(true)
                    setAuditionListRefresh(true)
                }
                else{
                    alert("신청 취소에 실패하였습니다.\n"+result.data.message)
                }
    
            }
            else{
                alert("다시 시도해주세요.\n")
            }
        }

    }
    const onClickSignUpExamRecovery = async (e, signUpAudition) =>{

        if(window.confirm("해당 신청자를 복구 하시겠습니까?")){

            const result = await customAxios.post("/api/recoverSignUpAudition",{
                SignUpAudition_Index: signUpAudition.SignUpAudition_Index
            })
    
            if(result && result.data){
                if(result.data.state === 100){
                    alert("복구 되었습니다.")
                    setAuditionListRefresh(true)
                    setSignUpAuditionListRefresh(true)
                }
                else{
                    alert("복구에 실패하였습니다.\n"+result.data.message)
                }
    
            }
            else{
                alert("다시 시도해주세요.\n")
            }
        }

    }

    const onClickSignUpAuditionListSave = async(e) => {
        if(window.confirm("명단을 스프레드 시트에 저장하시겠습니까?\n[시간이 많이 소요되는 작업입니다.]")){
            setWait(true)
            const response = await customAxios.post('/api/uploadSignUpAudition', { auditionUID: auditionUID });
            if(response.data.state === 100){
                setAuditionListRefresh(true)
                setSignUpAuditionListRefresh(true)
                alert("명단이 저장되었습니다.")
            }
            else{
                alert("명단 저장에 실패하였습니다.\n"+response.data.message)
            }
            setWait(false)
        }
    }

    const displaySignUpAuditionList = () =>{
        
        if(auditionUID){
            if(signUpAuditionList&& signUpAuditionList.length>0){
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 명단</div>
                        <div className="flex w-full gap-2 border bg-Cgcolor text-white p-2">
                            <div className="flex-none w-7 overflow-hidden whitespace-nowrap">번호</div>
                            <div className="-ml-2 w-20 overflow-hidden whitespace-nowrap text-center">이름</div>
                            <div className="-ml-2 w-24 overflow-hidden whitespace-nowrap text-center">생년</div>
                            {/* <div className="text-center w-24 overflow-hidden whitespace-nowrap">신청일</div> */}
                            <div className="text-center w-36 overflow-hidden whitespace-nowrap">참가번호(수정번호)</div>
                            <div className="text-center w-36 overflow-hidden whitespace-nowrap">연락처</div>
                        </div>
                        {signUpAuditionList.map((signUpAudition,index)=>{
                            const Rday = dayjs(signUpAudition.SignUpExam_RegistrationDate)
                                // onClick={(e)=>onClickSignUpExamList(e,signUpExam)}
                            return (
                                    <div className="flex gap-2 bg-gray-50  hover:cursor-pointer border text-sm hover:bg-blue-100" key={"signUp"+signUpAudition.SignUpExam_Index}>
                                        <div  onClick={(e)=>{onClickSignUpAuditionList(e,signUpAudition)}} className="flex-none w-7 text-center">{index+1}</div>
                                        <div  onClick={(e)=>{onClickSignUpAuditionList(e,signUpAudition)}} className="w-20 text-center overflow-hidden whitespace-nowrap">{signUpAudition.SignUpAudition_Name}</div>
                                        <div  onClick={(e)=>{onClickSignUpAuditionList(e,signUpAudition)}} className="w-24 text-center overflow-hidden whitespace-nowrap">{signUpAudition.SignUpAudition_BirthDay}</div>
                                        {/* <div  onClick={(e)=>{onClickSignUpExamList(e,signUpExam)}} className="w-24 text-center overflow-hidden whitespace-nowrap">{Rday.format("YYYY-MM-DD")}</div> */}
                                        <div onClick={(e)=>{onClickSignUpAuditionList(e,signUpAudition)}} className="w-40 text-center">{signUpAudition.rowKey}</div>
                                        <div onClick={(e)=>{onClickSignUpAuditionList(e,signUpAudition)}} className="w-36 text-center">{signUpAudition.SignUpAudition_Phone}</div>
                                        <button onClick={(e)=>{onClickSignUpAuditionUpdate(e,signUpAudition)}} className="rounded bg-Cgcolor text-white w-10 overflow-hidden whitespace-nowrap" >수정</button>
                                        <button onClick={(e)=>{onClickSignUpExamCancel(e,signUpAudition)}} className="rounded bg-Cgcolor text-white w-10 overflow-hidden whitespace-nowrap" >취소</button>
                                    </div>
                                
                            )
                        })}
                        <div className="flex w-full justify-end mt-3">
                            <button onClick={onClickSignUpAuditionListSave} className="rounded bg-green-800 text-white" >명단 저장</button>
                        </div>

                    </div>
                )
            }
            else{
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 명단</div>
                        <div className="w-96">참가자가 없습니다.</div>
                    </div>
                )
            }
                
            

        }
        else{
            return (
                <div className="w-96"> 참가자 리스트를 가져올 오디션을 선택해주세요.</div>
            )
        }
        
    }

    const displayDropSignUpAuditionList = () =>{
        
        if(auditionUID){
            if(dropsignUpAuditionList&& dropsignUpAuditionList.length>0){
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 취소 명단</div>
                        <div className="flex w-full gap-2 border bg-Cgcolor text-white p-2">
                            <div className="flex-none w-7 overflow-hidden whitespace-nowrap">번호</div>
                            <div className="-ml-2 w-20 overflow-hidden whitespace-nowrap text-center">이름</div>
                            <div className="-ml-2 w-24 overflow-hidden whitespace-nowrap text-center">생년</div>
                            {/* <div className="text-center w-24 overflow-hidden whitespace-nowrap">신청일</div> */}
                            <div className="text-center w-36 overflow-hidden whitespace-nowrap">참가번호(수정번호)</div>
                            <div className="text-center w-36 overflow-hidden whitespace-nowrap">연락처</div>
                        </div>
                        {dropsignUpAuditionList.map((signUpAudition,index)=>{
                            const Rday = dayjs(signUpAudition.SignUpExam_RegistrationDate)
                                // onClick={(e)=>onClickSignUpExamList(e,signUpExam)}
                            return (
                                    <div className="flex gap-2 bg-gray-50  hover:cursor-pointer border text-sm hover:bg-blue-100" key={"signUp"+signUpAudition.SignUpAudition_Index}>
                                        <div  className="flex-none w-7 text-center">{index+1}</div>
                                        <div  className="w-20 text-center overflow-hidden whitespace-nowrap">{signUpAudition.SignUpAudition_Name}</div>
                                        <div  className="w-24 text-center overflow-hidden whitespace-nowrap">{signUpAudition.SignUpAudition_BirthDay}</div>
                                        {/* <div  className="w-24 text-center overflow-hidden whitespace-nowrap">{Rday.format("YYYY-MM-DD")}</div> */}
                                        <div className="w-40 text-center">{signUpAudition.rowKey}</div>
                                        <div className="w-36 text-center">{signUpAudition.SignUpAudition_Phone}</div>
                                        <button onClick={(e)=>{onClickSignUpExamRecovery(e,signUpAudition)}} className="rounded bg-Cgcolor text-white w-10 overflow-hidden whitespace-nowrap" >복구</button>
                                    </div>
                                
                            )
                        })}

                    </div>
                )
            }
            else{
                return (
                    <div className="flex flex-col">
                        <div className="mt-1 mb-1 font-bold">참가 취소 명단</div>
                        <div className="w-96">취소자가 없습니다.</div>
                    </div>
                )
            }

        }
    }
    

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (wait) {
                const message = '명단 저장 중입니다. 창을 닫으시면 작업이 중단됩니다.';
                event.returnValue = message; // 크롬 및 다른 브라우저에서 메시지를 표시
                return message; // 일부 브라우저에서 필요
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [wait]); // wait 상태에 따라 effect 재실행

    return (
        <div className="flex w-full">
            <WaitDialog Open={wait} />
            {/* <WaitProgressDialog Open={wait} max={max} currentValue={currentValue} /> */}
            <section className="flex flex-col p-2 w-full gap-2">
                {displayAuditionList()}
                <div className="flex justify-end">
                    <button onClick={onClickAuditionWrite} className="rounded bg-Cgcolor text-white" > 등록 </button>
                </div>
            </section>
            <section className="flex flex-col p-2 w-auto gap-2">
                {displaySignUpAuditionList()}
                {displayDropSignUpAuditionList()}
            </section>
        </div>
        
    )
}

export default AdminAuditionList;
