import Footer from "../../Components/Fixed/Footer";

import { useState, useEffect } from "react";
import WriteSignUpAuditionForm from "../../Components/Form/WriteSignUpAuditionForm";

function SignUpAudition() {

    const [start, setStart] = useState(false)

    return (
        <div className=''>
            <div className='flex flex-col pt-1 items-center'>
                <WriteSignUpAuditionForm/>
            </div>
            <Footer />
        </div>
    )
}

export default SignUpAudition;
