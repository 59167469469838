import Footer from "../../Components/Fixed/Footer";
import CheckSignUpAudtionForm from "../../Components/Form/CheckSignUpAudtionForm";

function SignUpCheck() {
    
    return (
        <div className=''>
            <div className='flex w-full px-2 pt-1 justify-center'>
                <CheckSignUpAudtionForm/>
            </div>
            <Footer />
        </div>
    )
}

export default SignUpCheck;
