import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import StoreHeader from '../../Components/Fixed/StoreHeader';
import StoreDisplay from '../../Components/Store/StoreDisplay';
import { customAxios } from '../../Components/Common/customAxios';


function StoreDetail() {

  const [start, setStart] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const storeCode = searchParams.get("Store_Code")
  const [displayData, setDisplayData] = useState(null)

  useEffect(() => {
    setStart(true)
  }, [])

  useEffect(() => {

    const getStoreData = async () => {
      const result = await customAxios.post("/api/getStoreData", { code: storeCode })
      if (result.data.state === 101) {
        setDisplayData(result.data.response)
        // console.log(result.data.response)
        if (result.data.response.soldOut === "true") {
          alert("품절된 상품입니다.")
        }
      }
      else {
        alert("존재하지 않는 스토어입니다.")
      }

    }

    if (start && storeCode) {
      getStoreData()
    }

  }, [start])




  if (storeCode) {
    return (
      <div className='max-w-lg mx-auto'>
        <Header />
        <StoreHeader />
        <div className='flex w-full'>
          {displayData && <StoreDisplay displayData={displayData} />}
        </div>
        <Footer />
      </div>

    );
  }


}

export default StoreDetail;

